import React, { Suspense, createContext, useContext, useEffect, useState } from "react";
import { Router, Location, Redirect } from "@reach/router";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Header from "./menu/header";
import Home4 from "./pages/home4";
import { createGlobalStyle } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TagManager from 'react-gtm-module'
import NotFound from "./pages/NotFound";
import AuctionTop from "./pages/AuctionTop";
import Cookies1 from "./components/Cookies";
import DemoTest from "./pages/DemoTest";
import AdminLogin from "./pages/admin/Login";
import AdminDashboard from "./pages/admin/Dashboard";
import Faq from "./pages/Faq";
import ConnectViaWhhatsapp from "./pages/ConnectViaWhhatsapp";
import ThankYou from "./pages/ThankYou";
import ItemDetailsBuyNow from "./pages/ItemDetailsBuyNow";
import ItemDetailsViewOwned from "./pages/ItemDetailsViewOwned";
import TradeDetails from "./pages/TradeDetails";
import Cart from "./pages/Cart";
import Cookies from "js-cookie";
import { Axios } from "../core/axios";
const Auction = React.lazy(() => import("./pages/Auction"));
const Colectiongrey = React.lazy(() => import("./pages/colectionGrey"));
const Contact = React.lazy(() => import("./pages/contact"));
const DomainBranding = React.lazy(() => import("./pages/DomainBranding"));
const Trade = React.lazy(() => import("./pages/Trade"));
const SearchResult = React.lazy(() => import("./pages/searchResult"));
const AuctionDetails = React.lazy(() => import("./pages/AuctionDetails"));
const NftDetail = React.lazy(() => import("./pages/NftDetail"));
const ItemDetailReduxgrey = React.lazy(() =>
  import("./pages/ItemDetailReduxGrey")
);
const Dummy = React.lazy(() => import("./components/Dummy"));
const Privacy = React.lazy(() => import("./pages/privacy"));
const TermsOfUse = React.lazy(() => import("./pages/termsOfUse"));
const KYCPolicy = React.lazy(() => import("./pages/kycPolicy"));
const AMLPolicy = React.lazy(() => import("./pages/amlPolicy"));
const EnsResult = React.lazy(() => import("./components/EnsResult"));
const Purchase = React.lazy(() => import("./pages/Purchase"));
const AboutUs = React.lazy(() => import("./pages/aboutUs"));
const Sponsors = React.lazy(() => import("./pages/sponsor"));
const SearchPage = React.lazy(() => import("./pages/searchPage"));
const CarrerPage = React.lazy(() => import("./pages/career"));
const TldsPage = React.lazy(() => import("./pages/tlds"));
const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const CartContext = createContext({
  data: 0,
  setData: () => { },
});

export const useCartContext = () => useContext(CartContext);

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);

const App = () => {
  const [data, setData] = useState(0);

  const getCartCount = () => {
    const customHeaders = {
      Authorization: "Token " + Cookies.get("Token")
    }
    Axios.get(`/domain-cart?is_web3=${(Cookies.get("W2W3Switch") && Cookies.get("W2W3Switch") == 'true') ? 'True' : 'False'}`, { headers: customHeaders }).then(resp => {
      setData(resp.data.data?.count);
    }).catch(() => { })
  }

  useEffect(() => {
    if (Cookies.get("Token")) {
      getCartCount();
    }
  }, [])

  return (
    <div className="wraper">
      <CartContext.Provider value={{ data, setData }}>
        <GlobalStyles />
        <ToastContainer
          autoClose={2000}
          position="bottom-right"
          hideProgressBar="false"
          closeOnClick="true"
          pauseOnHover="true"
          draggable="true"
          theme="dark"
        />
        <Cookies1 />
        <Header />
        <Suspense
          fallback={
            <>
              <div className="siteLoader page active"></div>
            </>
          }
        >
          <PosedRouter>
            <ScrollTop path="/">
              <Home4 exact path="/">
                <Redirect to="/home" />
              </Home4>

              <SearchResult path="/result/:searchKey" />
              <Trade path="/trade" />
              <Cart path="/cart" />

              <Auction path="/auction" />
              <AuctionTop path="/auction/:nftTitle" />
              <AuctionDetails path="/auctionDetail/:nftId" />

              <Colectiongrey path="/profile/:collectionId" />

              <ItemDetailsBuyNow path="/details" />
              <ItemDetailsViewOwned path="/details/:nftId" />
              <TradeDetails path="tradeDetail/:nftId" />

              <Purchase path="/purchase/:slug" />

              <Contact path="/contact" />

              <Dummy path="/dummy/:search" />
              <EnsResult path="/ensResult/:query" />
              <Privacy path="/privacy" />
              <TermsOfUse path="/terms-of-use" />
              <KYCPolicy path="/kyc-policy" />
              <AMLPolicy path="/aml-policy" />
              <AboutUs path="/about-us" />
              <Sponsors path="refer/:address" />
              <SearchPage path="/search" />
              <DomainBranding path="/domainbranding" />
              <TldsPage path="/tlds" />
              <NftDetail path="/nft-detail/:tokenId" />
              <CarrerPage path="/careers" />
              <Faq path="/faq" />
              <ThankYou path="/thankyou" />

              <DemoTest path="mdemo" />

              <NotFound path="/not-found" />

              <ConnectViaWhhatsapp path="/connectviawhatsapp" />

              <AdminLogin path="/admin" />
              <AdminDashboard path="/admin/dashboard" />
            </ScrollTop>
          </PosedRouter>
        </Suspense>

        <ScrollToTopBtn />
      </CartContext.Provider>
    </div>
  );
};
export default App;
