import React from "react";
import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../assets/_swiper.scss";
import { navigate } from "@reach/router";
import { useState } from "react";
import Cookies from "js-cookie";
import { APP_DATA } from "../../core/utils";
import { IoIosSearch } from "react-icons/io";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const item = {
  item1: "/img/home-slider/1.svg",
  item2: "/img/home-slider/2.svg",
  item3: "/img/home-slider/3.svg",
  item4: "/img/home-slider/4.svg",
  item5: "/img/home-slider/5.svg",
  item6: "/img/home-slider/6.svg",
  item7: "/img/home-slider/7.svg",
  item8: "/img/home-slider/8.svg",
  item9: "/img/home-slider/9.svg",
  item10: "/img/home-slider/4.svg",
  item12: "/img/home-slider/8.svg",
  item13: "/img/home-slider/1.svg",
  item14: "/img/home-slider/2.svg",
  item15: "/img/home-slider/3.svg",
  item16: "/img/home-slider/4.svg",
  item17: "/img/home-slider/3.svg",
  item18: "/img/home-slider/6.svg",
  item19: "/img/home-slider/8.svg",
  item20: "/img/home-slider/1.svg",
};

const Slidermainparticle = () => {
  const [searchInput, setSearchInput] = useState("");

  const navigateToSearch = () => {
    if (searchInput) {
      if (Cookies.get("W2W3Switch") == "true") {
        window.location.href = ("/ensResult/" + searchInput);
      } else {
        window.location.href = ("/result/" + searchInput);
      }
    }
  };

  return (
    <div className="container mt-md-herosec" style={{ zIndex: "10" }}>
      <div className="row align-items-center">
        <div className="col-md-6 col-12">
          <div className="spacer-single"></div>
          <h6>
            <span className="text-uppercase ls-20">Echobit Market</span>
          </h6>
          <Reveal className="onStep" keyframes={fadeInUp} delay={300} duration={900} triggerOnce>
            <h1 className="col-white">
              Discover Collect & Sell <img height="57" width="140" style={{ marginTop: '-10px' }} src='/img/background/home-hero-heading-icon.png' alt='hero-icon' /> Fractional <span className="color"> {
                (localStorage.getItem("chainId") === APP_DATA.chainId2 && Cookies.get("W2W3Switch") === "true") ? "ENS" : "NFTs"
              }.</span>
            </h1>
          </Reveal>
          <form
            id="form_search_big"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              navigateToSearch();
            }}
            className="d-flex align-items-center mb-3"
            name="form_search_big">
            <div className="position-relative flex-grow-1">
              <input
                className="form-control mb-0"
                id="text_search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                name="text_search"
                placeholder="Search Domain"
                type="text"
              />
              <button type="submit" className="border-0" id="btn-submit">
                {/* <img src="/img/icons/search-icon.svg" className="h-100" alt="search" /> */}
                <IoIosSearch color="#fff" size={20} />
              </button>
              <div className="clearfix"></div>
            </div>
          </form>
          <div className="spacer-10"></div>
        </div>
        <div className="col-md-6 col-12">
          <div className="content-right d-flex mt-4">

          </div>
        </div>
      </div>
    </div>
  );
};

export default Slidermainparticle;
