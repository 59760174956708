import { navigate } from "@reach/router";
import React, { memo, Suspense } from "react";
import { APP_DATA } from "../../core/utils";
import { priceToBNB, priceUpToDigit } from "../../utils";
import { ReactFitty } from "react-fitty";
import { BsGlobe } from "react-icons/bs";
import { SiBinance } from "react-icons/si";
const ImageComponent = React.lazy(() => import("../ImageComponent"));

const NftCard = ({ index, nft = null, type = null, className = "col-lg-3 col-md-4 col-sm-6 col-12 mt-2 mt-md-4" }) => {
  const onClickHandler = () => {
    if (nft.auctionId) {
      window.location.href = ("/auctionDetail/" + nft.auctionId);
    } else {
      window.location.href = ("/tradeDetail/" + nft.itemId);
    }
  }

  const colors = [
    { bg: "light-blue-list-block", border: "blue-list-border" },
    { bg: "light-orange-list-block", border: "orange-list-border" },
    { bg: "light-lovender-list-block", border: "lovender-list-border" },
    { bg: "light-yellow-list-block", border: "yellow-list-border" }
  ];

  return (
    <div className={className}>
      <div className={"item-card cursor-pointer pink-list-border"} onClick={onClickHandler}>
        <div className="item-header-profile d-flex px-2">
          <Suspense fallback={<></>}>
            <ImageComponent path={"/img/profile-img.jpg"} className={"item-user"} aspectRatio={{ height: "50px", width: "50px" }} altTxt={"123"} />
          </Suspense>
          <span className="ms-2" style={{ width: "100%", paddingRight: "10px", marginTop: "13.5px", fontWeight: "700", fontSize: "20px", color: "#0d0c22" }}><ReactFitty maxSize="20">{nft?.userDetail?.username}</ReactFitty></span>
        </div>
        <div className="item-main text-center">

          <h3 className="price">
            {APP_DATA.CURR_CURRENCY == "MATIC" ? (
              <img
                alt="polygon"
                src="/img/icons/polygon-secondary.svg"
                height="25px"
                className="me-2"
                style={{ marginTop: "-5px" }}
              />
            ) : (
              <SiBinance color="#F19A39" className="me-2" />
            )}
            {type === "auction" ? <>
            <span className="currency">HB</span> {priceUpToDigit(priceToBNB(nft?.hightestbid))}
            </> : <>
              {priceUpToDigit(priceToBNB(nft?.totalprice))}
            </>}
            <span className="currency">{APP_DATA.CURR_CURRENCY}</span>
          </h3>

          <div className="item-title mt-0 pt-0">
            <h2 className="mb-0" style={{ fontWeight: "200" }}>
              <ReactFitty minSize="1" maxSize="20">
                <BsGlobe color="#F19A39" />  {nft?.title}
              </ReactFitty>
            </h2>
          </div>
        </div>
        <div className="item-footer">
          {/* {type === "auction" && (
            <div className="auction-box">
              <span>Auction - <strong>8h : 20m</strong></span>
            </div>
          )} */}
          <div className="item-desc">
            {type === "auction" ?
              <span>
                <strong style={{ display: "inline-block", maxWidth: "calc(100% - 90px)" }}><ReactFitty minSize="1" maxSize="16"></ReactFitty></strong> Buy Now
              </span>
              :
              <span>
                <strong style={{ display: "inline-block", maxWidth: "calc(100% - 90px)" }}><ReactFitty minSize="1" maxSize="16"></ReactFitty> </strong>Buy Now
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NftCard);
