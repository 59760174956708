import React from "react";

const EmptyNftCard = ({className = "col-lg-3 col-md-4 col-sm-6 col-12 mt-2 mt-md-4" }) => {
  return (
    <div className={className}>
      <div
        className="item-card cursor-pointer"
        style={{
          border: "2px solid #06aeb1",
          height: "290px",
          maxWidth: "calc(100% + 300px)"
        }}
      >
        <div className="h-100 text-center">
          <div className={"siteLoader content-emptycard active"}>
            <img src="/img/app-icon.png" width="80%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyNftCard;
