import ABI from "../braands_abi.json";
import ABI1 from "../abi_braands_eth.json";
import ABI2 from "../abi_ens_minter.json";
import Cookies from "js-cookie";

export const APP_DATA = {
  API_URL: "https://api.echobit.io/api",
  RPC_BNB: "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
  RPC_ETH: "https://goerli.infura.io/v3/6aa0e0ccc9554706b76505b22ad03cf4",
  RPC_MATIC: "https://polygon-rpc.com/",
  chainId1: "97",
  chainId2: "5",
  chainId3: "137",
  contractAddress: "0x0Ba423e8DE85CBf211c89a5F44Fb3E8BFde69a34",
  // contractAddress: "0x423581da66859ea2e6282a3a2784dcc35084e8ec",
  ABI: ABI,
  contractAddressENS: "0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5",
  ABIENS: ABI1,
  contractAddressENSMinter: "0x7d196accb6e695310f9bbbba465b1e655e6f8e01",
  ABIENSMinter: ABI2,
  contractAddressETH: "0x380dcDe4AD886D1227dD239f3a13Ad323C5848A6",
  contractAddressMATIC: "0xe3a5284e979e1a60ed0abc1841070144057b989f",
  resolverAddress: "0x42D63ae25990889E35F215bC95884039Ba354115",
  CURR_CURRENCY:
    localStorage.getItem("chainId") === "5"
      ? "ETH"
      : localStorage.getItem("chainId") === "137"
      ? "MATIC"
      : "BNB",
  WEB3_DOMAIN_NAME: ".ebt"
};
