import React, { useState } from 'react';
import { BiShareAlt } from "react-icons/bi";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { TiSocialInstagramCircular } from "react-icons/ti";
import { RiHeartAddLine, RiHeartFill } from "react-icons/ri";
import axios from 'axios';
import Cookies from 'js-cookie';
import { APP_DATA } from '../../core/utils';
import { toast } from 'react-toastify';


export default function SocialShare({ data }) {
    const [socialActive, activeState] = useState(false);
    const [favActive, favActiveState] = useState(false);
    const [activeLink, setActiveLink] = useState("");

    const saveFavorite = (status) => {
        if (!status) {
            const customHeaders = {
                Authorization: "Token " + Cookies.get("Token")
            }
            const formData = new FormData();
            console.log(data);
            if (data.auctionId) {
                formData.append("favourite_type", "package");
                formData.append("domain_name", data?.title);
                formData.append("image", data.image);
                formData.append("myIcon", data.myIcon);
                formData.append("price", data.hightestbid);
                formData.append("type", "auction");
            } else {
                formData.append("favourite_type", "package");
                // formData.append("icon_id", data?.icon?.id);
                // formData.append("font_id", data?.font?.id);
                formData.append("domain_name", data?.title);
                // formData.append("layout", data?.layout);
                formData.append("price", data?.registration_price);
            }

            axios.post(APP_DATA.API_URL + "/favourite", formData, { headers: customHeaders }).then(res => {
                toast.success('Added In Favourite Successfully.');
                favActiveState(!status);
            }).catch(err => {
                toast.error('Internal Server Error!!!');
            })
        } else {
            favActiveState(!status);
        }
    }

    const genLink = () => {
        if (!socialActive) {
            const formData = new FormData();
            console.log('Generating', data);
            if (data.auctionId) {
                formData.append("type", "auction");
                formData.append("domain_name", data?.title);
                // formData.append("image", data.image);
                // formData.append("myIcon", data.myIcon);
                formData.append("price", data.hightestbid);
            } else {
                formData.append("type", "logo");
                formData.append("title", data?.title);
                formData.append("logoSVG", data?.logoSVG);
                formData.append("logo_title", data?.logo_title);
                formData.append("price", data?.total_bnb_price);
                formData.append("dollerPrice", data?.registration_price);
                formData.append("icon", data?.icon?.icon_svg);
                formData.append("font", data?.font?.font_svg);
                formData.append("layout", data?.layout);
                formData.append("tags", data?.tags);
            }

            let query = ``;
            axios.post(APP_DATA.API_URL + "/shareapi", formData).then(res => {
                toast.success("Domain Sharing Link Copied Succeccfully.");
                query += "/purchase/" + res?.data?.data?.slug;
                navigator.clipboard.writeText(window.location.origin + query);
                setActiveLink(window.location.origin + query);
            }).catch(error => {
                query += "/purchase/" + error?.response?.data?.data?.slug;
                navigator.clipboard.writeText(window.location.origin + query);
                setActiveLink(window.location.origin + query);
                toast.success("Domain Sharing Link Copied Succeccfully.");
            })
        }
    }

    return (
        <>
            <div className="sharing-option">
                {/* <div className={"like-icon " + favActive}>
                    <i onClick={() => saveFavorite(favActive)} className="lined"><RiHeartAddLine /></i>
                    <i onClick={() => saveFavorite(favActive)} className='filled'><RiHeartFill /></i>
                </div> */}
                <div className={"share-icon " + socialActive} onClick={() => { genLink(); activeState(!socialActive); }}>
                    <i><BiShareAlt color="#06aeb1" /></i>
                </div>
                <div className={"socialShareBox " + socialActive}>
                    <div className="socialShareBox__inner">
                        <h6>Share:</h6>
                        <ul>
                            <li className='socialShareBox__facebook'>
                                <a target="_blank" href={"https://www.facebook.com/sharer.php?u=" + activeLink}><i><BsFacebook color="#cf0b5b" /></i> Facebook</a>
                            </li>
                            <li className='socialShareBox__twitter'>
                                <a target="_blank" href={`https://twitter.com/intent/tweet?text=${activeLink}`}><i><AiFillTwitterCircle color="#cf0b5b" /></i> Twitter</a>
                            </li>
                            {/* <li className='socialShareBox__instagram'>
                                <a target="_blank" href="/"><i><TiSocialInstagramCircular color="#cf0b5b" /></i> Instagram</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )

}