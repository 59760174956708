import React, { useContext, useEffect, useState } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { Link } from "@reach/router";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useWeb3React } from "@web3-react/core";
import { navigate } from "@reach/router";
import { Slide, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "../../store/selectors";
import * as actions from "../../store/actions/thunks";
import { APP_DATA } from "../../core/utils";
import { createGlobalStyle } from "styled-components";
import { IoExitSharp } from "react-icons/io5";
import { SiBinance } from "react-icons/si";
import { FaEthereum } from "react-icons/fa";
import { TbWallet } from "react-icons/tb";
import Cookies from "js-cookie";
import { formatWalletAddr } from "../../utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import Web3 from "web3";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { Axios } from "../../core/axios";
import { useCartContext } from "../app";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const GlobalStyles = createGlobalStyle`
.top-search-bar{
  display: flex;
  margin: 0px auto;
  width: auto;
  border: 1px solid rgba(0,0,0,0.2);
  padding: 5px 0px !important;
  overflow: hidden;
  border-radius: 6px;

  .top-search-bar-left{
    position: relative;
    transition: all 1s;
    &.active{
      input{
        margin-left: 0px;
      }
    }
    input{
      border: 0px;
      margin-left: -290px;
      width: 350px;
      @media(min-width:1200px){
        margin-left: -437px;
        width: 500px;
      }
      transition: all 1s;
      &:focus{
        box-shadow: none !important;
      }
    }
    .text-input-btn-left{
      transition: all 1s;
      position: absolute;
      top: 0px;
      right: 0px;
      background: #f2993a;
      padding: 2px 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
    }
  }
  .top-search-bar-right{
    display: flex;
    align-items: center;
  }
  .top-search-bar-center {
    display: flex;
    .switch-button-inside{
      background: #222;
      margin-left: 0.5rem;
      height: 100%;
      padding: 2px 5px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      margin-right: 5px;
      cursor: pointer;
    }
  }
}

  .network-select-box-container{
    position: absolute;
    right: 20px;
    color: black;
    background: #06aeb1;
    width: 68px;
    border-radius: 10px;
    li{
      list-style: none;
      padding: 0px;
      margin: 0px;
      padding: 3px 6px;
      color: white;
      font-weight: 500;
    }
  }
  .network-select-box{
    margin-left: 0.5rem;
    padding: 2px 13px 2px 12px;
    background: #06aeb1;
    border-radius: 6px;
    color: #fff;
  }
  @keyframes widthAni {
    from {width: 0%;}
    to {width: 100%;}
  }
  .breakpoint__l-down {
    .menu{
    height: 100vh;
    transition: all 0.2s;
    animation: widthAni 0.4s;
    }
  }
  .mb--2{
    margin-bottom: -2px;
  }
  .mt--2{
    margin-top: -2px;
  }
  @keyframes marginRight {
    from { margin-right: -200px; }
    to {margin-right: 0%; }
  }
  .toggle-network-container{
    background: white;
    margin-right: -133px;
    transition: all 1s;
    &.active{
      // display: block !important;
      // animation: marginRight 1s;
      margin-right: 0px;
    }
  }

  .toggle-network-container-center{
    margin-right: -147px;
    transition: all 1s;
    &.active{
      // display: block !important;
      // animation: marginRight 1s;
      margin-right: 0px;
    }
  }

  .profile-btn-header {
    border-color: #f2993a !important;
    background: #f2993a !important;
    color: #fff !important;
  }

  .logout-btn-header {
    border-color: #06aeb1 !important;
    background: #06aeb1 !important;
    color: #fff !important;
  }

  .add-to-cart-noti{
    border-color: #111 !important;
    background: #111 !important;
    color: #fff !important;
  }
`;

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const metaMaskConnector = new InjectedConnector({ supportedNetworks: [1, 4] });
const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    [APP_DATA.chainId1]: APP_DATA.RPC_BNB,
    [APP_DATA.chainId2]: APP_DATA.RPC_ETH,
    [APP_DATA.chainId3]: APP_DATA.RPC_MATIC,
  },
  qrcode: true,
  pollingInterval: 15000,
});

const Header = function (props) {
  const dispatch = useDispatch();
  const [walletBalance, setWalletBalance] = React.useState(null);
  const [networkToggle, setNetworkToggle] = React.useState(APP_DATA.chainId1);
  const [web3Switch, setWeb3Switch] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [openPopup, setOpenPopup] = useState(false);
  const [checkTermsOfUse, setCheckTermsOfUse] = useState(true);

  const [showmenu, btn_icon] = useState(false);
  const matches = useMediaQuery("(min-width:568px)");
  const [expanded, setExpanded] = React.useState(false);
  const [showSwitch, setShowSwitch] = React.useState(false);
  const [urlRefer, setUrlRefer] = useState(null);

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.refer) {
      setUrlRefer(params?.refer);
    } else {
      setUrlRefer(null);
    }
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const { active, account, library, activate, deactivate } = useWeb3React();
  const user = useSelector(selectors.userState).userDetails.data;

  const [openDisclaimer, setOpenDisclaimer] = useState(false);

  const [toggleNetwork, setToggleNetwork] = useState(true);
  const [toggleWeb3Web2, setToggleWeb3Web2] = useState(true);

  const { data: cartCount } = useCartContext();

  const getIsUserRegister = (walletAddress = account) => {
    let bodyData = new FormData();
    bodyData.append("wallet_address", walletAddress);
    axios
      .post(APP_DATA.API_URL + "/login", bodyData)
      .then((res) => {
        Cookies.set("Token", res.data.token);
      })
      .catch((err) => {
        window.location.href =
          "/profile/" + walletAddress + window.location.search;
      });
  };

  const handleIsActive = async () => {
    try {
      let metaMaskActive = await metaMaskConnector.isAuthorized();
      if (localStorage.getItem("walletDetails")) {
        if (metaMaskActive) {
          let walletDetails = JSON.parse(localStorage.getItem("walletDetails"));
          if (walletDetails.address) {
            await activate(metaMaskConnector);
          }
        } else {
          localStorage.removeItem("walletDetails");
        }
      } else {
        if (localStorage.getItem("walletconnect")) {
          let walletDetails = JSON.parse(localStorage.getItem("walletconnect"));
          localStorage.setItem("chainId", walletDetails.chainId);

          if (walletDetails.address || walletDetails.accounts)
            await activate(walletConnectConnector);
        } else if (active) {
          if (metaMaskActive) {
            let walletDetails = JSON.stringify({ address: account });
            localStorage.setItem("walletDetails", walletDetails);
            getIsUserRegister();
          }
        }
      }
      active && getBalance();
    } catch (error) { }
  };

  const signOut = () => {
    try {
      deactivate(metaMaskConnector);
      deactivate(walletConnectConnector);

      localStorage.removeItem("walletDetails");
      localStorage.removeItem("walletconnect");
      Cookies.remove("Token");
      toast.success("Signout Successful!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark",
        transition: Slide,
      });
      setTimeout(
        () => navigate(urlRefer ? "/" + window.location.search : "/"),
        2000
      );
    } catch (error) { }
  };

  const getBalance = async () => {
    try {
      const res = await library.eth.getBalance(account);
      setWalletBalance(library.utils.fromWei(res));
    } catch (error) { }
  };

  const changeNetwork = async (toggle = networkToggle) => {
    let web3 = new Web3(Web3.givenProvider);
    // if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
    //   web3 = new Web3(APP_DATA.RPC_ETH);
    // } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
    //   web3 = new Web3(APP_DATA.RPC_MATIC);
    // }

    let chainId = await web3?.eth.getChainId();
    chainId = chainId.toString();

    if (chainId) {
      if (toggle === APP_DATA.chainId1) {
        if (chainId !== APP_DATA.chainId1) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [
                { chainId: web3.utils.toHex(parseInt(APP_DATA.chainId1)) },
              ],
            });
            localStorage.setItem("chainId", toggle);
            setNetworkToggle(toggle);
            toast.success("You are on Binance Testnet,");
            setTimeout(
              () =>
              (window.location.href = urlRefer
                ? "/" + window.location.search
                : "/"),
              1000
            );
          } catch (error) { }
        }
      } else if (toggle === APP_DATA.chainId2) {
        if (chainId !== APP_DATA.chainId2) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [
                { chainId: web3.utils.toHex(parseInt(APP_DATA.chainId2)) },
              ],
            });
            localStorage.setItem("chainId", toggle);
            setNetworkToggle(toggle);
            toast.success("You are on Ethereum Testnet.");
            setTimeout(
              () =>
              (window.location.href = urlRefer
                ? "/" + window.location.search
                : "/"),
              1000
            );
          } catch (error) { }
        }
      } else if (toggle === APP_DATA.chainId3) {
        if (chainId !== APP_DATA.chainId3) {
          try {
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [
                { chainId: web3.utils.toHex(parseInt(APP_DATA.chainId3)) },
              ],
            });
            localStorage.setItem("chainId", toggle);
            setNetworkToggle(toggle);
            toast.success("You are on Matic Testnet.");
            setTimeout(
              () =>
              (window.location.href = urlRefer
                ? "/" + window.location.search
                : "/"),
              1000
            );
          } catch (error) { }
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("chainId")) {
      let chainId = localStorage.getItem("chainId");
      setNetworkToggle(chainId);
      changeNetwork(chainId);
    } else {
      changeNetwork(APP_DATA.chainId1);
    }
    if (active) {
      if (!Cookies.get("Token")) {
        dispatch(actions.loginUser(account));
      }
      dispatch(actions.getUserDetails());
    }
    if (!window.location.href.includes("connectviawhatsapp")) {
      handleIsActive();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  const connectMetaMask = async () => {
    if (checkTermsOfUse) {
      try {
        if (window.ethereum) {
          await activate(metaMaskConnector);
          handleIsActive();
          setOpenPopup(false);
          toast.success("Signin Successful!");
          setOpenDisclaimer(true);
        } else {
          toast.error("Metamask is not available on this browser!!!");
        }
      } catch (error) { }
    } else {
      toast.error("Please accept terms of use");
      return;
    }
  };

  const connectWallet = async () => {
    if (checkTermsOfUse) {
      try {
        await activate(walletConnectConnector);
        handleIsActive();
        const account = JSON.parse(
          localStorage.getItem("walletconnect")
        )?.accounts;
        alert(account.length);
        if (account && account.length > 0) {
          getIsUserRegister(account[0]);
          setOpenPopup(false);
          toast.success("Signin Successful!");
          setOpenDisclaimer(true);
        }
      } catch (error) { }
    } else {
      toast.error("Please accept terms of use");
      return;
    }
  };

  const changeNetworkToggle = (e) => {
    changeNetwork(e.target.value);
  };

  useEffect(() => {
    if (Cookies.get("W2W3Switch") && Cookies.get("W2W3Switch") === "true") {
      setWeb3Switch("true");
    } else {
      setWeb3Switch("false");
    }
  }, []);

  const changeW2W3 = () => {
    if (web3Switch === "true") {
      setWeb3Switch(false);
      Cookies.set("W2W3Switch", "false");
      window.location.href = urlRefer ? "/" + window.location.search : "/";
      // setNetWorkName("Web3");
    } else {
      setWeb3Switch(true);
      // setNetWorkName("Web2");
      Cookies.set("W2W3Switch", "true");
      toast.success("Now You Can Buy ENS Domains.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Slide,
      });
      setTimeout(
        () =>
        (window.location.href = urlRefer
          ? "/" + window.location.search
          : "/"),
        2000
      );
    }
  };

  const navigateToSearch = () => {
    if (searchKey) {
      console.log(
        "Entering",
        APP_DATA.CURR_CURRENCY,
        Cookies.get("W2W3Switch")
      );
      if (Cookies.get("W2W3Switch") === "true") {
        window.location.href = "/ensResult/" + searchKey;
      } else {
        window.location.href = "/result/" + searchKey;
      }
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSwitchTab = () => {
    setShowSwitch((prev) => !prev);
  };

  return (
    <>
      <GlobalStyles />

      <header className={`navbar white ${props.className}`} id="myHeader">
        <div className="container">
          <div className="row w-100-nav">
            <div className="logo px-0">
              <div className="navbar-title navbar-logo-pink navbar-item">
                <NavLink to={urlRefer ? "/" + window.location.search : "/"}>
                  <img
                    width="180"
                    height="41"
                    src="/img/app-icon-sm.png"
                    className="img-logo img-logo-sm img-fluid"
                    alt="#"
                  />
                  <img
                    width="180"
                    height="50"
                    src="/img/echobit-dark-logo.png"
                    className="img-logo img-logo-lg img-fluid d-block"
                    alt="#"
                  />
                  <img
                    width="484"
                    height="110"
                    src="/img/echobit-dark-logo.png"
                    className="img-logo img-fluid d-3"
                    alt="#"
                  />
                  <img
                    width="484"
                    height="110"
                    src="/img/echobit-dark-logo.png"
                    className="img-logo img-fluid d-4"
                    alt="#"
                  />
                  <img
                    width="484"
                    height="110"
                    src="/img/echobit-dark-logo.png"
                    className="img-logo img-fluid d-none"
                    alt="#"
                  />
                </NavLink>
              </div>
              <div className="navbar-title navbar-logo-white navbar-item">
                <NavLink to={urlRefer ? "/" + window.location.search : "/"}>
                  <img
                    width="180"
                    height="41"
                    src="/img/app-icon-sm.png"
                    className="img-logo img-fluid d-block"
                    alt="#"
                  />
                  <img
                    width="484"
                    height="110"
                    src="/img/echobit-dark-logo.png"
                    className="img-logo img-fluid d-3"
                    alt="#"
                  />
                  <img
                    width="484"
                    height="110"
                    src="/img/echobit-dark-logo.png"
                    className="img-logo img-fluid d-4"
                    alt="#"
                  />
                  <img
                    width="484"
                    height="110"
                    src="/img/echobit-dark-logo.png"
                    className="img-logo img-fluid d-none"
                    alt="#"
                  />
                </NavLink>
              </div>
            </div>

            <BreakpointProvider>
              <Breakpoint l down>
                {showmenu && (
                  <div className="menu">
                    {active ? (
                      <div className="mainside_mobile_view d-flex">
                        <div
                          id="de-click-menu-profile"
                          className="de-menu-profile"
                          onClick={() => navigate("/profile/" + account)}
                        >
                          <img
                            src={
                              user?.profile_pic
                                ? user?.profile_pic
                                : "../../img/author_single/author_thumbnail.jpg"
                            }
                            alt="profile"
                          />
                        </div>
                        <div className="logout_btn ms-2" onClick={signOut}>
                          <IoExitSharp />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </Breakpoint>

              <div className="d-flex mx-auto w-auto align-items-center top-search-bar-wrapper">
                <div className="top-search-bar">
                  <div
                    className={`top-search-bar-left ${toggleNetwork && "active"
                      }`}
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        navigateToSearch();
                      }}
                    >
                      <input
                        type="text"
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        className="form-control mb-0 px-3 text-input"
                        placeholder="Search"
                      />
                      <button
                        className="d-flex border-0 text-input-btn-left"
                        id="btn-submit-1"
                        onClick={() => {
                          navigateToSearch();
                          setToggleNetwork(true);
                          setToggleWeb3Web2(true);
                        }}
                      >
                        <IoIosSearch color="#fff" size={20} />
                      </button>
                    </form>
                  </div>
                  <div className="top-search-bar-center">
                    <div
                      className="switch-button-inside"
                      onClick={() => {
                        setToggleWeb3Web2((prev) => !prev);
                        setToggleNetwork(true);
                      }}
                    >
                      {web3Switch === "true" ? "Web3" : "Web2"}
                    </div>
                    <div
                      className={`toggle-network-container-center d-flex align-items-center px-1 ${!toggleWeb3Web2 && "active"
                        }`}
                    >
                      <input
                        type="radio"
                        label="radio-checkinput"
                        className="form-check-input"
                        onChange={changeW2W3}
                        checked={web3Switch == "true"}
                        name="web2web3switch"
                        value={"Web3"}
                      />
                      <span className="toggle-network-text1 text-black">
                        &nbsp;Web3
                      </span>
                      &nbsp;&nbsp;
                      <input
                        type="radio"
                        className="form-check-input"
                        onChange={changeW2W3}
                        checked={web3Switch == "false" || !web3Switch}
                        name="web2web3switch"
                        value={"Web2"}
                      />
                      <span className="toggle-network-text1 text-black">
                        &nbsp;Web2
                      </span>
                    </div>
                  </div>
                  <div className="top-search-bar-right">
                    <Tooltip
                      title="Change Network"
                      placement="top-start"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#06aeb1",
                          },
                        },
                      }}
                    >
                      <div
                        className="network-select-box cursor-pointer d-flex align-items-center h-100 "
                        onClick={() => {
                          setToggleNetwork((prev) => !prev);
                          setToggleWeb3Web2(true);
                        }}
                      >
                        {networkToggle === APP_DATA.chainId1 ? (
                          <SiBinance />
                        ) : networkToggle === APP_DATA.chainId2 ? (
                          <FaEthereum />
                        ) : (
                          <img
                            alt="polygon"
                            src="/img/icons/polygon.svg"
                            width="20px"
                          />
                        )}
                      </div>
                    </Tooltip>

                    <div
                      className={`toggle-network-container d-flex px-1 ${!toggleNetwork && "active"
                        }`}
                    >
                      <input
                        type="radio"
                        label="radio-checkinput"
                        className="form-check-input"
                        onChange={changeNetworkToggle}
                        checked={networkToggle === APP_DATA.chainId1}
                        name="network"
                        value={APP_DATA.chainId1}
                        style={{ accentColor: "red" }}
                      />
                      <span className="toggle-network-text text-black">
                        &nbsp;BSC
                      </span>
                      &nbsp;&nbsp;
                      <input
                        type="radio"
                        className="form-check-input"
                        onChange={changeNetworkToggle}
                        checked={networkToggle === APP_DATA.chainId3}
                        name="network"
                        value={APP_DATA.chainId3}
                      />
                      <span className="toggle-network-text text-black">
                        &nbsp;MATIC
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {showSwitch && !matches && (
                <div
                  className={`toggle-switch ${showSwitch ? "show-switch" : "hide"
                    }`}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "0px",
                  }}
                >
                  <span className="switcher switcher-1">
                    <input
                      type="checkbox"
                      id="switcher-1"
                      checked={web3Switch === "true" ? false : true}
                      onChange={changeW2W3}
                    />
                    <label for="switcher-1"></label>
                  </span>
                </div>
              )}
            </BreakpointProvider>

            <div className="breakpoint__xl-only ">
              <div className="menu">
                <div className="navbar-item">
                  <a
                    href={
                      urlRefer
                        ? "/auction" + window.location.search
                        : "/auction"
                    }
                  >
                    Auction
                    <span className="lines"></span>
                  </a>
                </div>
                <div className="navbar-item">
                  <a
                    href={
                      urlRefer ? "/trade" + window.location.search : "/trade"
                    }
                  >
                    Trade
                    <span className="lines"></span>
                  </a>
                </div>
              </div>
            </div>

            <div className="mainside">
              {!active ? (
                <div
                  className="connect-wallet"
                  onClick={() => setOpenPopup(true)}
                >
                  <TbWallet />{" "}
                  <span className="connect-wallet-txt">Connect Wallet</span>
                </div>
              ) : (
                <div className="d-flex">
                  <div
                    title={formatWalletAddr(account)}
                    id="de-click-menu-profile"
                    className="logout_btn profile-btn-header"
                    onClick={() =>
                      (window.location.href = "/profile/" + account)
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                  </div>
                  <div onClick={() => window.location.href = "/cart"} className="logout_btn add-to-cart-noti ms-2">
                    <HiOutlineShoppingCart />
                    <span className="add-to-cart-overlay">
                      {cartCount}
                    </span>
                  </div>
                  <div className="logout_btn logout-btn-header ms-2" onClick={signOut}>
                    <IoExitSharp />
                  </div>
                </div>
              )}
            </div>
          </div>

          {openPopup && (
            <div className="checkout2">
              <div className="maincheckout">
                <button
                  className="btn-close"
                  onClick={() => setOpenPopup(false)}
                >
                  x
                </button>
                <div className="heading mt-3 text-center">
                  <h2 className="mb-0">Connect Wallet</h2>
                  <p className="mb-4 text-black">Unique and Yours Forever</p>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb30 px-5">
                    <span
                      className={`box-url text-center cursor-pointer ${checkTermsOfUse ? "" : "disabled"
                        }`}
                      onClick={connectMetaMask}
                    >
                      <span
                        className="box-url-label"
                        style={{ top: "5px", right: "5px", fontSize: "12px" }}
                      >
                        Most Popular
                      </span>
                      <img
                        src="./img/wallet/1.png"
                        alt=""
                        style={{ width: "70px", height: "70px" }}
                        className="mb20 mt-2"
                      />
                    </span>
                    <h4 className="text-center mt-2 mb-0">Metamask</h4>
                  </div>
                  <div
                    className="col-lg-6 mb30 px-5"
                    style={{ borderLeft: "1px solid rgba(0, 0, 0, .2)" }}
                  >
                    <span
                      className={`box-url text-center cursor-pointer ${checkTermsOfUse ? "" : "disabled"
                        }`}
                      onClick={connectWallet}
                    >
                      <img
                        src="./img/wallet/4.png"
                        style={{ width: "70px", height: "70px" }}
                        alt=""
                        className="mb20 mt-2"
                      />
                    </span>
                    <h4 className="text-center mt-2 mb-0">WalletConnect</h4>
                  </div>
                </div>

                <div className="my-3 text-center checkTermsOfUseSection">
                  <input
                    id="checkTermsOfUse"
                    type="checkbox"
                    checked={checkTermsOfUse}
                    onChange={() => setCheckTermsOfUse((prev) => !prev)}
                  />
                  <label for="checkTermsOfUse" className="ms-2 text-black">
                    By clicking, you agree to our{" "}
                    <a href="/files/TERMS-OF-USE.pdf" target="_blank">
                      Terms of use
                    </a>
                    , <br />
                    <a href="/files/Privacy_Policy.pdf" target="_blank">
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a href="/files/Privacy_Policy.pdf" target="_blank">
                      Cookies Policy
                    </a>
                    .
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};
export default Header;
