import { useEffect, useState } from "react";
import { ReactFitty } from "react-fitty";
import { createGlobalStyle } from "styled-components";
import { APP_DATA } from "../../core/utils";
import { priceToBNB, priceUpToDigit, unixToDateTime } from "../../utils";
import SocialShare from "../components/SocialShare";
import ApexChart from "../components/Charts/ApexChart";
import axios from "axios";
import { RiAuctionLine, RiHeartAddLine, RiShoppingCartLine } from "react-icons/ri";
import { MdOutlineAccountBalanceWallet, MdOutlineDns } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { IoCartOutline, IoPricetagsOutline } from "react-icons/io5";
import Footer1 from "../components/footer1";
import { useWeb3React } from "@web3-react/core";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Web3 from "web3";
import { Link } from "@reach/router";
import { GrShare } from "react-icons/gr";
import { AiOutlineDownload, AiOutlineTag } from "react-icons/ai";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .btn-main svg{width:18px;height:18px;margin-right:3.5px;}
  .navbar.sticky.white{
    background: #fff !important;
    border-bottom: none;
    .navbar-logo-white{
      display: none;
    }
    .navbar-logo-pink{
      display: block;
    }
  }
  .col-white {
      color: #0d0c22;
  }
  .text-dull{
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .btn-main.white{
    background: rgba(131,100,266, .2);
  }
  
  .domain-main{
    //min-height: 400px !important;
  }
  .domain-main-wrapper{
    possition:relative;
    height:300px;
    
    -webkit-box-shadow: 0 2.4px 10px rgb(0 0 0 / 10%);
            box-shadow: 0 2.4px 10px rgb(0 0 0 / 10%);
    .item-footer{
      border-top: 1px solid #ccc;
    }
  }
  .my-domain-main-wrapper{
    &::before{z-index: 1 !important;}
    &::after{z-index: 1 !important;}
  }
  .border-radius-20{
    border-radius: 20px;
  }
  .estimated-price{
    font-size: 15px;
    border: 1px solid #f20c6a;
    border-radius: 25px;
    padding: 2px 10px;
  }
`;

function ItemDetailsViewOwned({ nftId }) {
  const [nft, setNft] = useState({});
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});

  const [input, setInput] = useState({
    sellPrice: "",
    sellQuantity: 0,
    validityDays: "",
  })
  const [price, setPrice] = useState({
    itemPrice: 0,
    servicePrice: 0,
    totalPrice: 0,
  });
  const [buyOutPrice, setBuyOutPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [buyNowDollerPrice, setBuyNowDollerPrice] = useState();
  const [buyNowBNBPrice, setBuyNowBNBPrice] = useState(nft?.price);

  const [suggestedDomains, setSuggestedDomains] = useState([]);
  const { library, active, account } = useWeb3React();

  const [openMenu, setOpenMenu] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupAuction, setOpenPopupAuction] = useState(false);

  const [transactionStatus, setTransactionStatus] = useState(0);
  const [transactionMsg, setTransactionMsg] = useState("");
  const [transactionHash, setTransactionHash] = useState("");

  const getNftData = async () => {
    let web3 = new Web3(APP_DATA.RPC_BNB);
    let contract = new web3.eth.Contract(
      APP_DATA.ABI,
      APP_DATA.contractAddress
    );

    if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
      web3 = new Web3(APP_DATA.RPC_ETH);
      contract = new web3.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressETH
      );
    } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
      web3 = new Web3(APP_DATA.RPC_MATIC);
      contract = new web3.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressMATIC
      );
    }

    let mint_time = await contract.methods.Mint_time(nftId).call();
    let initial_price = await contract.methods.intial_price(nftId).call();
    let minter_address = await contract.methods.Minter_address(nftId).call();
    initial_price = web3.utils.fromWei(initial_price);
    let max_supply = await contract.methods.max_supply(nftId).call();
    let history = [];
    for (let i = 0; i <= 10; i++) {
      try {
        let tempHistory = [];
        let t = await contract.methods.history_a(nftId, i).call();
        tempHistory.push(t);
        t = await contract.methods.history_amt(nftId, i).call();
        tempHistory.push(t);
        t = await contract.methods.history_p(nftId, i).call();
        tempHistory.push(web3.utils.fromWei(t));
        t = await contract.methods.history_t(nftId, i).call();
        tempHistory.push(t);
        history.push(tempHistory);
      } catch (error) {
        break;
      }
    }
    let balance_of;
    if (account)
      balance_of = await contract.methods.balanceOf(account, nftId).call();
    console.log(balance_of);
    let token_uri = await contract.methods.tokenURI(nftId).call();
    let title;
    let token_uri_split = token_uri?.split(".");
    if (token_uri_split.includes("infura-ipfs")) {
      const resAPI = await axios.get(token_uri);
      title = resAPI.data.name ? resAPI.data.name : resAPI.data.title;
    }
    setNft({
      ...nft,
      title,
      history,
      minter_address,
      balance_of,
      tokenId: nftId,
      max_supply,
      mint_time,
      price: initial_price,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (nftId && account) {
      getNftData();
    }
  }, [account]);

  const regEx = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
  const positveIntegers = /^[1-9]\d*$/;

  const onChangeHandler = async (e) => {
    if (e.target.name === "sellPrice") {
      const flag = regEx.test(e.target.value);
      setInput({ ...input, [e.target.name]: flag ? e.target.value : "" });
    } else if (
      e.target.name === "sellQuantity" || e.target.name === "validityDays"
    ) {
      const flag = positveIntegers.test(e.target.value);
      setInput({ ...input, [e.target.name]: flag ? e.target.value : "" });
    } else {
      setInput({ ...input, [e.target.name]: e.target.value });
    }

    let sellQuantity = input.sellQuantity;
    let sellPrice = input.sellPrice;
    if (e.target.name === "sellQuantity") sellQuantity = e.target.value;
    if (e.target.name === "sellPrice") sellPrice = e.target.value;
    let contract = new library.eth.Contract(
      APP_DATA.ABI,
      APP_DATA.contractAddress
    );
    if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
      contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressETH
      );
    } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
      contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressMATIC
      );
    }
    if (e.target.name === "validityDays" && e.target.value) {
      let perFinal = parseInt(e.target.value);
      let finalP = nft.dollerPrice * parseFloat(perFinal);
      let finalPB = nft.price * perFinal;

      setBuyNowDollerPrice(finalP);
      setBuyNowBNBPrice(finalPB);
    }
    if (e.target.name === "validityDays" && e.target.value === "") {
      setBuyNowDollerPrice(nft.dollerPrice);
      setBuyNowBNBPrice(nft.price);
    }
    if (sellQuantity === "") {
      setPrice({
        itemPrice: 0,
        servicePrice: 0,
        totalPrice: 0,
      });
    } else if (sellPrice !== "" && parseInt(sellQuantity) > 0 && contract) {
      let mprice = library.utils.toWei(sellPrice);
      let res;
      openPopupAuction
        ? (res = await contract.methods
          .feeForAuction(mprice, parseInt(sellQuantity))
          .call())
        : (res = await contract.methods
          .calculate_fee(mprice, parseInt(sellQuantity))
          .call());
      let itemPrice = sellPrice;
      let servicePrice = library.utils.fromWei(res);
      let totalPrice = (
        parseFloat(itemPrice) + parseFloat(servicePrice)
      ).toString();
      setPrice({ ...price, itemPrice, servicePrice, totalPrice: totalPrice });
    }
  };

  const listItemAuctionHandler = async () => {
    console.log('listItemAuctionHandler');
    let contract = new library.eth.Contract(
      APP_DATA.ABI,
      APP_DATA.contractAddress
    );
    if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
      contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressETH
      );
    } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
      contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressMATIC
      );
    }
    console.log(contract);
    if (contract) {
      let mprice = library.utils.toWei(input.sellPrice);
      let buyOutPrice1;
      if (buyOutPrice === "") {
        buyOutPrice1 = 0;
      } else {
        buyOutPrice1 = library.utils.toWei(buyOutPrice);
        let totalPrice = parseInt(nft.max_supply) * parseFloat(input.sellPrice);

        if (parseFloat(buyOutPrice) < parseFloat(totalPrice)) {
          toast.error("Buy out price should be greater then total price!!!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
      }
      // setLoading(true);
      console.log(nft);
      if (nft.max_supply === nft.balance_of) {
        const res = await contract.methods
          .feeForAuction(mprice, parseInt(nft.max_supply))
          .call();
        console.log(res);
        contract.methods
          .createAuction(
            nftId,
            mprice,
            parseInt(nft.max_supply),
            parseInt(input.validityDays),
            buyOutPrice1 === 0 ? false : true,
            buyOutPrice1
          )
          .send({ from: account, value: res })
          .then((trans) => {
            toast.success("List to  Successfully.", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            setOpenPopup(false);
            setTimeout(() => window.open("/auction", "_self"), 500);
          });
      }
    }
    console.log("List to Successfully");
  };

  const listItemHandler = async () => {
    if (library) {
      let contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddress
      );
      if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
        contract = new library.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddressETH
        );
      } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
        contract = new library.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddressMATIC
        );
      }
      if(input.sellQuantity <= nft.balance_of){
        if (contract) {
          setLoading(true);
          let mprice = library.utils.toWei(input.sellPrice);
          const res = await contract.methods
            .calculate_fee(mprice, parseInt(input.sellQuantity))
            .call();
          contract.methods
            .createIteam(nftId, mprice, parseInt(input.sellQuantity))
            .send({ from: account, value: res })
            .then((trans) => {
              toast.success("List to marketplace Successfully.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              setLoading(false);
              setOpenPopup(false);
              setTimeout(() => window.open("/trade", "_self"), 2000);
            });
        }
      } else {
        toast.error("The Sell Quantity is more then available quantity.")
      }
    }
  };

  return (
    <div>
      <GlobalStyles />
      <section className="mt-3 pt-0 mt-xl-5 pt-xl-5" style={{ background: "#fff" }}>
        <div className="container">
          <div className="row mt-md-3 pt-md-4">
            {loading ? (
              <div className={`siteLoader ${loading ? "page active" : ""}`}></div>
            ) : (
              <>
                <div className="col-md-6 border-left ps-3 order-2 order-md-1">
                  <div className="item_info position-relative">
                    <div className="row">
                      <div className="col-md-8">
                        <h3 className="mb-1 fw-600 text-dull">Domain Information</h3>

                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="mb-2 w-100 fw-800">
                              <ReactFitty minSize="1" maxSize="36">
                                {nft.title}
                              </ReactFitty>
                            </h2>
                            {/* </div> */}
                            {/* <div className="col-md-6 d-flex align-items-center"> */}
                            <h4 className="mb-1 fw-500 text-dull">
                              {priceUpToDigit(nft.price)} {APP_DATA.CURR_CURRENCY}{" "}
                              {/* <span className="mb-3 fw-800 text-dull">
                                | ${nft.price}{" "}
                              </span> */}
                              (per unit price)
                            </h4>
                          </div>
                        </div>

                        {nft?.estimatedPrice && <h4 className="mb-4 fw-500 text-dull">
                          <span className="estimated-price">Estimated Price :</span> <b>${nft.estimatedPrice}</b>
                        </h4>}

                      </div>

                      {/* <div className="col-md-4 text-md-end" style={{ paddingRight: "50px" }}>
                        {account ? (
                          !nftId ? (
                            <></>
                          ) : !nft.seller ? (
                            <>
                              <button
                                className="btn-capsule"
                                onClick={() => {
                                  account
                                    ? downloadInvoice()
                                    : toast.error(
                                      "Please Connect Wallet To Continue!!!"
                                    );
                                }}
                              >
                                <AiOutlineDownload /> Download Invoice
                              </button>
                              <button
                                className="btn-capsule me-2 me-md-0"
                                onClick={() => {
                                  account
                                    ? (window.location.href =
                                      "/nft-detail/" + nftId)
                                    : toast.error(
                                      "Please Connect Wallet To Continue!!!"
                                    );
                                }}
                              >
                                <MdOutlineDns /> Manage DNS
                              </button>
                            </>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div> */}

                      {/* <SocialShare data={nft} /> */}

                      {nft?.tags && (
                        <div className="nft-tags">
                          {nft.tags.split(",").map((ele, i) => (
                            <div key={i} className="nft-tag">
                              {ele}
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="row mt-4 pe-0">
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="1"
                              title="HashTag Mentions"
                              subtitle=""
                              value={stats.hashtags ? stats.hashtags : 0}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="2"
                              title="Google Search Trends"
                              subtitle=""
                              value={stats.google}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="3"
                              title="YouTube Search"
                              subtitle=""
                              value={stats.twitter}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="de_tab mt-4">
                        {localStorage.getItem("item_data_buy") && !nftId ? (
                          <></>
                        ) : (
                          <ul className="de_nav">
                            <li
                              id="Mainbtn0"
                              className={openMenu === 1 ? "active" : ""}
                            >
                              <span onClick={() => setOpenMenu(1)}>Details</span>
                            </li>
                            {nft.mint_time && (
                              <li
                                id="Mainbtn1"
                                className={openMenu === 2 ? "active" : ""}
                              >
                                <span onClick={() => setOpenMenu(2)}>
                                  History
                                </span>
                              </li>
                            )}
                          </ul>
                        )}

                        <div className="de_tab_content">
                          {openMenu === 1 && (
                            <div className="tab-1 onStep fadeIn">
                              <div className="d-block mb-3">
                                {nft?.tokenId && (
                                  <table className="table detail-table">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Listed Time</th>
                                        <th>Max Supply</th>
                                        {nft?.balance_of && <th>Balance</th>}
                                        {nft?.units_left && <th>Unit left</th>}
                                        {nft?.totalprice && (
                                          <th>Current Price</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <span className="detail-id">
                                            {nft?.tokenId}
                                          </span>
                                        </td>
                                        <td>
                                          <h6>
                                            {unixToDateTime(nft?.mint_time).slice(
                                              0,
                                              11
                                            )}
                                            <span>
                                              {unixToDateTime(
                                                nft?.mint_time
                                              ).slice(
                                                12,
                                                unixToDateTime(nft?.mint_time)
                                                  ?.length
                                              )}
                                            </span>
                                          </h6>
                                        </td>
                                        <td>{nft?.max_supply}</td>
                                        {nft?.balance_of && (
                                          <td>
                                            <span className="unit">
                                              {nft?.balance_of}
                                            </span>
                                          </td>
                                        )}
                                        {nft?.units_left && (
                                          <td>
                                            <span className="unit">
                                              {nft?.units_left}
                                            </span>
                                          </td>
                                        )}
                                        {nft?.totalprice && (
                                          <td>
                                            <h6>
                                              {priceToBNB(nft?.totalprice)}{" "}
                                              {APP_DATA.CURR_CURRENCY}
                                            </h6>
                                          </td>
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            </div>
                          )}

                          {openMenu === 2 && (
                            <div className="tab-2 onStep fadeIn">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th>Address</th>
                                    <th>Amount</th>
                                    <th>Price</th>
                                    <th>Time</th>
                                  </tr>
                                  <tr>
                                    <td>
                                      {nft?.minter_address.slice(0, 5) +
                                        "..." +
                                        nft.minter_address.slice(
                                          nft.minter_address.length - 4,
                                          nft.minter_address.length
                                        )}
                                      <Link to="/">
                                        <GrShare className="ms-2" />
                                      </Link>
                                    </td>
                                    <td>{nft?.max_supply}</td>
                                    <td>
                                      {priceUpToDigit(nft.price)}{" "}
                                      {APP_DATA.CURR_CURRENCY}
                                    </td>
                                    <td>{unixToDateTime(nft.mint_time)}</td>
                                  </tr>
                                  {nft?.history?.map((ele) => {
                                    return (
                                      <tr>
                                        <td>
                                          {ele[0].slice(0, 5) +
                                            "..." +
                                            ele[0].slice(
                                              ele[0].length - 5,
                                              ele[0].length
                                            )}
                                        </td>
                                        <td>{ele[1]}</td>
                                        <td>
                                          {priceUpToDigit(ele[2])}{" "}
                                          {APP_DATA.CURR_CURRENCY}
                                        </td>
                                        <td>{unixToDateTime(ele[3])}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {/* <hr className="hr-line" /> */}
                          {/* button for checkout */}

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <div className="checkout h-100">
                    <div className="maincheckout h-100">
                      <div className="heading text-center">
                        <h2 className="fs-30">Manage Domain</h2>
                      </div>

                      <div>
                        <h3 className="mb-2">DNS records</h3>
                        <p>
                          Define how your domain behaves, like showing your website content and delivering your email.
                        </p>
                      </div>


                      <div className="">
                        <div className="d-flex flex-row gap-4 mt-3 flex-wrap">
                          <button className="btn-capsule me-2 me-md-0 rounded"
                            onClick={() => {
                              account
                                ? (window.location.href =
                                  "/nft-detail/" + nftId)
                                : toast.error(
                                  "Please Connect Wallet To Continue!!!"
                                );
                            }}
                          >
                            <MdOutlineDns /> Manage DNS
                          </button>
                        </div>
                        <hr className="my-1" />

                        <div>
                          <h3 className="mb-2 mt-3">Invoice</h3>
                          <p>
                            Download invoices instantly for streamlined transactions and organized record-keeping. Simplify your success today!
                          </p>
                        </div>

                        <button className="btn-capsule rounded"
                        // onClick={() => {
                        //   account
                        //     ? downloadInvoice()
                        //     : toast.error(
                        //       "Please Connect Wallet To Continue!!!"
                        //     );
                        // }}
                        >
                          <AiOutlineDownload /> Download Invoice
                        </button>

                        <hr className="my-1" />

                        <div>
                          <h3 className="mb-2 mt-3">List Your Domain</h3>
                          <p>
                            The right place to auction and trade your premium domain.
                          </p>
                        </div>

                        <div className="d-flex flex-row gap-4 mt-4 flex-wrap">
                          <button
                            style={{ minWidth: "195.08px" }}
                            className="btn-main btn-secondary lead m-0"
                            onClick={() => {
                              account
                                ? setOpenPopupAuction(true)
                                : toast.error(
                                  "Please Connect Wallet To Continue!!!"
                                );
                            }}
                          >
                            <RiAuctionLine /> List to auction
                          </button>
                          <button
                            className="btn-main lead m-0"
                            onClick={() => {
                              account
                                ? setOpenPopup(true)
                                : toast.error(
                                  "Please Connect Wallet To Continue!!!"
                                );
                            }}
                          >
                            <AiOutlineTag /> List to marketplace
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <section
        className="pb-0"
        style={{ background: "white", paddingTop: "250px" }}
      >
        <Footer1 type="pink" overlay={true} />
      </section>

      {openPopup && (
        <div className="checkout2">
          <div className="maincheckout nft-checkout">
            <button
              className="btn-close"
              onClick={() => {
                // clearMarketPlace();
                setOpenPopup(false);
              }}
            >
              x
            </button>
            <div className="heading  text-center">
              <h2 className="mb-0">List item To marketplace</h2>
            </div>
            <p>
              You are about to list a{" "}
              <span className="bold">{nft?.title} </span>
              item to marketplace from you.
            </p>
            <div className="detailcheckout">
              <div className="listcheckout">
                <h6>
                  Enter quantity to sell <span className="color"> {nft.balance_of} available</span>
                </h6>
                <input
                  type="number"
                  name="sellQuantity"
                  onChange={onChangeHandler}
                  value={input.sellQuantity}
                  style={{ color: "#222" }}
                  id="sellQuantity"
                  className="form-control"
                  placeholder="Enter Quantity"
                />
                {/* <span className="quantity">{nft?.max_supply}</span> */}
              </div>
            </div>
            <div className="detailcheckout">
              <div className="listcheckout">
                <h6>Enter per unit price for sell (In {APP_DATA.CURR_CURRENCY})</h6>
                <input
                  type="text"
                  name="sellPrice"
                  onChange={onChangeHandler}
                  value={input.sellPrice}
                  style={{ color: "#222" }}
                  id="sellPrice"
                  className="form-control m-0"
                  placeholder={`Enter per unit price for sell in ${APP_DATA.CURR_CURRENCY}`}
                />
              </div>
            </div>

            <div className="checkout-panel">
              <div className="row justify-content-center">
                <div className="col-md-4">
                  <div className="nft-check-block">
                    <div className="icon">
                      <img src="/img/checkout-icon-03.svg" alt="Like" />
                    </div>
                    <div className="detail">
                      <h6>Per Unit Price</h6>
                      <h5>
                        {input.sellPrice} {APP_DATA.CURR_CURRENCY}
                      </h5>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="nft-check-block">
                    <div className="icon">
                      <img src="/img/checkout-icon-04.svg" alt="Like" />
                    </div>
                    <div className="detail">
                      <h6>Listing Free</h6>
                      <h5>
                        {price.servicePrice} {APP_DATA.CURR_CURRENCY}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h4 className="checkout-total">
              <span>Total Price</span>
              {priceUpToDigit(price.totalPrice)} {APP_DATA.CURR_CURRENCY}
            </h4>

            <div className="text-center">
              <button className="btn-main lead mb-5" onClick={listItemHandler}>
                List Item
              </button>
            </div>
          </div>
        </div>
      )}

      {openPopupAuction && (
        <div className="checkout2">
          <div className="maincheckout">
            <button
              className="btn-close"
              onClick={() => {
                // clearAutcionpopup();
                setOpenPopupAuction(false);
              }}
            >
              x
            </button>
            <div className="text-center heading mb-2 mt-2">
              <h2 className="mb-0">List Item To Auction</h2>
            </div>
            <p className="text-center">
              You are about to list a <span className="bold">{nft?.title}</span>{" "}
              item to auction.
            </p>
            <div className="row">
              <div className="col-md-6 detailcheckout mb-3">
                <div className="listcheckout">
                  <h6>Enter per unit price (In {APP_DATA.CURR_CURRENCY})</h6>
                  <input
                    type="text"
                    name="sellPrice"
                    onChange={onChangeHandler}
                    value={input.sellPrice}
                    style={{ color: "#222" }}
                    id="sellPrice"
                    className="form-control rounded m-0"
                  />
                </div>
              </div>

              <div className="col-md-6 detailcheckout mb-3">
                <div className="listcheckout">
                  <h6>Enter validity days (Day)</h6>
                  <input
                    type="text"
                    name="validityDays"
                    onChange={onChangeHandler}
                    value={input.validityDays}
                    style={{ color: "#222" }}
                    id="validityDays"
                    className="form-control rounded m-0"
                  />
                </div>
              </div>
            </div>
            <div className="detailcheckout">
              <div className="listcheckout">
                <h6>
                  Buy Out Price (optional) <span>BNB</span>{" "}
                </h6>
                <input
                  type="text"
                  name="buyOutPrice"
                  onChange={(e) => {
                    const flag = regEx.test(e.target.value);
                    setBuyOutPrice(flag ? e.target.value : "");
                  }}
                  // value={buyOutPrice}
                  style={{ color: "#222" }}
                  id="validityDays"
                  className="form-control rounded m-0"
                />
              </div>
            </div>

            <div className="checkout-panel py-3 mb-3">
              <div className="row justify-content-center">
                {/* <div className="col-md-4">
                  <div className="nft-check-block">
                    <div className="icon">
                      <img src="/img/checkout-icon-03.svg" alt="Like" />
                    </div>
                    <div className="detail">
                      <h6>Per Unit Price</h6>
                      <h5>
                        {price.itemPrice} {APP_DATA.CURR_CURRENCY}
                      </h5>
                    </div>
                  </div>
                </div> */}

                <div className="col-md-4">
                  <div className="nft-check-block">
                    <div className="icon">
                      <img src="/img/checkout-icon-04.svg" alt="Like" />
                    </div>
                    <div className="detail">
                      <h6>Listing Free</h6>
                      <h5>
                        {price.servicePrice} {APP_DATA.CURR_CURRENCY}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="checkout-total">
              <span>Total Price </span>
              {parseFloat(nft.max_supply) * input.sellPrice}{" "}
              {APP_DATA.CURR_CURRENCY}
            </h4>
            <div className="text-center">
              <button
                className="btn-main lead mb-5"
                onClick={listItemAuctionHandler}
              >
                List Item
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}

export default ItemDetailsViewOwned