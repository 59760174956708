import { useEffect, useState } from "react";
import { ReactFitty } from "react-fitty";
import { createGlobalStyle } from "styled-components";
import { APP_DATA } from "../../core/utils";
import { priceUpToDigit } from "../../utils";
import SocialShare from "../components/SocialShare";
import ApexChart from "../components/Charts/ApexChart";
import axios from "axios";
import { RiHeartAddLine, RiShoppingCartLine } from "react-icons/ri";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { IoCartOutline, IoPricetagsOutline } from "react-icons/io5";
import Footer1 from "../components/footer1";
import { useWeb3React } from "@web3-react/core";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { Axios } from "../../core/axios";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .btn-main svg{width:18px;height:18px;margin-right:3.5px;}
  .navbar.sticky.white{
    background: #fff !important;
    border-bottom: none;
    .navbar-logo-white{
      display: none;
    }
    .navbar-logo-pink{
      display: block;
    }
  }
  .col-white {
      color: #0d0c22;
  }
  .text-dull{
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .btn-main.white{
    background: rgba(131,100,266, .2);
  }
  
  .domain-main{
    //min-height: 400px !important;
  }
  .domain-main-wrapper{
    possition:relative;
    height:300px;
    
    -webkit-box-shadow: 0 2.4px 10px rgb(0 0 0 / 10%);
            box-shadow: 0 2.4px 10px rgb(0 0 0 / 10%);
    .item-footer{
      border-top: 1px solid #ccc;
    }
  }
  .my-domain-main-wrapper{
    &::before{z-index: 1 !important;}
    &::after{z-index: 1 !important;}
  }
  .border-radius-20{
    border-radius: 20px;
  }
  .estimated-price{
    font-size: 15px;
    border: 1px solid #f20c6a;
    border-radius: 25px;
    padding: 2px 10px;
  }
`;

const projectId = process.env.REACT_APP_INFURA_IPFS_PROJECT_ID;
const projectSecret = process.env.REACT_APP_INFURA_IPFS_PROJECT_SECRET;
const projectIdAndSecret = `${projectId}:${projectSecret}`;
const ipfsURL = "https://braands.infura-ipfs.io/ipfs/";

const client = ipfsHttpClient({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: `Basic ${Buffer.from(projectIdAndSecret).toString(
      "base64"
    )}`,
  },
});

export default function ItemDetailsBuyNow() {
  const [loading, setLoading] = useState(false);
  const nft = JSON.parse(localStorage.getItem("item_data_buy") || "{}");
  const [stats, setStats] = useState({});
  const [userBalance, setUserBalance] = useState("");
  const [doWeHaveCoupon, setDoWeHaveCoupon] = useState(false);

  const getBalance = async () => {
    if (library) {
      try {
        const res = await library.eth.getBalance(account);
        setUserBalance(library.utils.fromWei(res));
      } catch (error) { }
    }
  };

  const [input, setInput] = useState({
    numberOfUnit: 10000,
    numberOfYear: "1",
    couponCode: '',
    appliedCoupon: false
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [suggestedDomains, setSuggestedDomains] = useState([]);
  const { library, active, account } = useWeb3React();

  const [transactionStatus, setTransactionStatus] = useState(0);
  const [transactionMsg, setTransactionMsg] = useState("");
  const [transactionHash, setTransactionHash] = useState("");
  const customHeaders = {
    Authorization: "Token " + Cookies.get("Token")
  }

  const getStats = (name) => {
    axios
      .get(APP_DATA.API_URL + "/getstats?name=" + name)
      .then((res) => {
        const data = {
          hashtags: res.data.data["hashtags-mentions"],
          google: res.data.data["google-search"],
          twitter: res.data.data["youtube-search"],
        };
        setStats(data);
      })
      .catch((err) => { });
  };

  const getSuggestedDomains = (name) => {
    axios
      .get(
        APP_DATA.API_URL +
        "/getsuggesteddomain?search=" +
        nft?.logo_title +
        "&page=1"
      )
      .then((response) => {
        setSuggestedDomains(response.data.suggeted_domain);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStats(nft?.logo_title);
    getSuggestedDomains();
  }, []);

  useEffect(() => {
    if (account) {
      getBalance();
    }
  }, [account]);

  async function uploadDataToIPFS() {
    const { domain } = nft;

    /* first, upload to IPFS */
    if (domain) {
      const data = JSON.stringify({
        name: domain,
      });
      try {
        const added = await client.add(data);
        const url = `${ipfsURL}${added.path}`;
        /* after file is uploaded to IPFS, return the URL to use it in the transaction */
        return url;
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  }

  const onClickCheckout = async (withPrice = true) => {
    if (!active) {
      toast.error("Please Connect Wallet To Continue!!!");
    } else if (library) {
      setTransactionStatus(10);
      if (nft.domain) {
        let contract = new library.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddress
        );
        if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
          contract = new library.eth.Contract(
            APP_DATA.ABI,
            APP_DATA.contractAddressETH
          );
        } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
          contract = new library.eth.Contract(
            APP_DATA.ABI,
            APP_DATA.contractAddressMATIC
          );
        }
        const price = library.utils.toWei(input.appliedCoupon ? '0' : totalPrice.toString());

        contract.methods
          .CheckValidity(1)
          .call()
          .then((resp) => {
            console.log(resp);
          });

        uploadDataToIPFS().then((url) => {
          setTransactionStatus(20);
          setTransactionMsg("Waiting Fot Web3 Transaction ...");
          contract.methods
            .mint(
              account,
              parseInt(input.numberOfUnit),
              url,
              price,
              parseInt(input.numberOfYear),
              Cookies.get("W2W3Switch") && Cookies.get("W2W3Switch") == 'true' ? 2 : 1,
            )
            .send({ from: account, value: price })
            .then((transaction) => {
              setTransactionStatus(50);
              setTransactionMsg("Web3 Transaction Completed.");
              setTransactionMsg("Waiting For Web2 Transaction ...");
              const txHash = transaction.transactionHash;
              setTransactionHash(txHash);
              const customeHeaders = {
                Authorization: "Token " + Cookies.get("Token"),
              };
              if (withPrice) {
                const mData = [{
                  mint_unit: input.numberOfUnit,
                  year: input.numberOfYear,
                  coupon_code: input.couponCode ? input.couponCode : null,
                  is_web3: false,
                  domain_name: nft.domain,
                  price: nft.registration_price
                }]
                Axios.post("/place-order", mData, { headers: customHeaders }).then(resp => {
                  Swal.fire({
                    title: "Order Placed",
                    text: "Your Domain Purchased Successfully.",
                    icon: 'success',
                  }).then(()=>{
                    window.location.href = "/profile/" + account;
                  });
                }).catch(err => {
                  console.log(err, "1");
                })
                // const formData = new FormData();
                // formData.append("domain", nft?.domain);
                // formData.append("price", nft?.registration_price);

                // axios
                //   .post(APP_DATA.API_URL + "/register-domain", formData, {
                //     headers: customeHeaders,
                //   })
                //   .then((response1) => {
                //     setTransactionStatus(100);
                //     setTransactionMsg("Web2 Transaction Completed.");
                //     toast.success("Buy Domain Successfully.");
                //     setLoading(false);
                //     window.location.href = "/profile/" + account;
                //   })
                //   .catch((error) => {
                //     toast.error(
                //       "Buy Domain Successfully On Smart Contract But Not On DN Simple.",
                //       {
                //         position: "bottom-right",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //       }
                //     );
                //     window.location.href = "/profile/" + account;
                //   });
              } else {
                setTransactionMsg("Web3 Transaction Completed.");
                toast.success("Buy Domain Successfully.");
                setTransactionStatus(100);
                setLoading(false);
                window.location.href = "/profile/" + account;
              }
            })
            .catch((err) => {
              console.error(err);
            });
        });
      } else {
        toast.error("Please wait...", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const onClickClaimNow = () => {
    if (!active) {
      toast.error("Please Connect Wallet To Continue!!!");
    } else if (library) {
      if (Number(input.numberOfYear) > 1) {
        toast.warning("You can only claim for 1 Year !!!")
        return;
      }
      setTransactionStatus(10);
      if (nft.domain) {
        let contract = new library.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddress
        );
        if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
          contract = new library.eth.Contract(
            APP_DATA.ABI,
            APP_DATA.contractAddressETH
          );
        } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
          contract = new library.eth.Contract(
            APP_DATA.ABI,
            APP_DATA.contractAddressMATIC
          );
        }
        const price = library.utils.toWei(totalPrice.toString());

        contract.methods
          .CheckValidity(1)
          .call()
          .then((resp) => {
            console.log(resp);
          });

        uploadDataToIPFS().then((url) => {
          setTransactionStatus(20);
          setTransactionMsg("Waiting Fot Web3 Transaction ...");
          contract.methods
            .mint(
              account,
              parseInt(input.numberOfUnit),
              url,
              price,
              parseInt(input.numberOfYear)
            )
            .send({ from: account, value: price })
            .then((transaction) => {
              setTransactionStatus(50);
              setTransactionMsg("Web3 Transaction Completed.");
              setTransactionMsg("Waiting For Web2 Transaction ...");
              const txHash = transaction.transactionHash;
              setTransactionHash(txHash);
              const customeHeaders = {
                Authorization: "Token " + Cookies.get("Token"),
              };
              const customHeaders = {
                Authorization: "Token " + Cookies.get("Token")
              }
              const mData = {
                domain: nft?.domain,
                price: nft?.registration_price,
                mint_unit: input.numberOfUnit,
                year: input.numberOfYear
              }
              Axios.post("/claim-domain", mData, { headers: customHeaders }).then(resp => {
                setTransactionMsg("Web3 Transaction Completed.");
                toast.success("Buy Domain Successfully.");
                setTransactionStatus(100);
                setLoading(false);
                window.location.href = "/profile/" + account;
              }).catch(error => {
                toast.error(error.response?.data?.message);
              })
            })
            .catch((err) => {
              console.error(err);
            });
        });
      } else {
        toast.error("Please wait...", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  const buyNow = (mDomain) => {
    const temp = {
      logo_title: mDomain.domain,
      title: mDomain.domain,
      ...mDomain,
    };
    localStorage.setItem("item_data_buy", JSON.stringify(temp));
    window.location.href = "/details";
  };

  const applyCoupon = () => {
    if(!input.couponCode) return;
    const customHeaders = {
      Authorization: "Token " + Cookies.get("Token")
    }
    Axios.get(`/validate-reward-coupon?domain_name=${nft.domain}&coupon_code=${input.couponCode}`, { headers: customHeaders }).then(resp => {
      let tempData = JSON.parse(JSON.stringify(input));
      if (resp.data.data?.is_redeemed) {
        tempData.appliedCoupon = false;
      } else {
        tempData.appliedCoupon = true;
      }
      setInput(tempData);
    }).catch(err => {
      let tempData = JSON.parse(JSON.stringify(input));
      tempData.appliedCoupon = false;
      setInput(tempData);
      toast.error("Invalid coupon code !!!")
    })
  }

  const unapplyCoupon = () => {
    setInput(prev => ({ ...prev, appliedCoupon: false }))
  }

  return (
    <div>
      <GlobalStyles />
      <section
        className="mt-3 pt-0 mt-xl-5 pt-xl-5"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="row mt-md-3 pt-md-4">
            {loading ? (
              <div
                className={`siteLoader ${loading ? "page active" : ""}`}
              ></div>
            ) : (
              <>
                <div className="col-md-6 border-left ps-3">
                  <div className="item_info position-relative">
                    <div className="row">
                      <div className="col-md-8">
                        <h3 className="mb-1 fw-600 text-dull">
                          Domain Information
                        </h3>

                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="mb-2 w-100 fw-800">
                              <ReactFitty minSize="1" maxSize="36">
                                {nft.domain}
                              </ReactFitty>
                            </h2>
                            {/* </div> */}
                            {/* <div className="col-md-6 d-flex align-items-center"> */}
                            <h4 className="mb-1 fw-500 text-dull">
                              {priceUpToDigit(nft.total_bnb_price)}{" "}
                              {APP_DATA.CURR_CURRENCY}{" "}
                              <span className="mb-3 fw-800 text-dull">
                                | ${nft.registration_price}{" "}
                              </span>
                              (price of domain)
                            </h4>
                          </div>
                        </div>

                        {nft?.estimatedPrice && (
                          <h4 className="mb-4 fw-500 text-dull">
                            <span className="estimated-price">
                              Estimated Price :
                            </span>{" "}
                            <b>${nft.estimatedPrice}</b>
                          </h4>
                        )}
                      </div>

                      <div className="row mt-4 pe-0">
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="1"
                              title="HashTag Mentions"
                              subtitle=""
                              value={stats.hashtags ? stats.hashtags : 0}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="2"
                              title="Google Search Trends"
                              subtitle=""
                              value={stats.google}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="3"
                              title="YouTube Search"
                              subtitle=""
                              value={stats.twitter}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <SocialShare data={nft} />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="checkout h-100">
                    <div className="maincheckout h-100">
                      <div className="heading text-center">
                        <h2>Checkout</h2>
                      </div>
                      <div className="detailcheckout mt-4">
                        <div className="listcheckout pe-2">
                          <h6 className="mb-1">Number of unit to be mint</h6>
                          <input
                            type="number"
                            name="numberOfUnit"
                            value={input.numberOfUnit}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                numberOfUnit: e.target.value,
                              })
                            }
                            style={{ color: "#222" }}
                            id="buyNowQty"
                            className="m-0 form-control rounded"
                          />
                        </div>
                        <div className="listcheckout ps-2">
                          <h6 className="mb-1">Number of years</h6>
                          <select
                            name="numberOfYear"
                            value={input.numberOfYear}
                            onChange={(e) => {
                              setInput({
                                ...input,
                                numberOfYear: e.target.value,
                              });
                              setTotalPrice(
                                parseInt(e.target.value) * nft.total_bnb_price
                              );
                            }}
                            disabled
                            readOnly
                            // onChange={onChangeHandler}
                            // value={input.validityDays}
                            className="m-0 form-control rounded"
                          >
                            <option value="">Select Year</option>
                            <option value="1">1 Year</option>
                            <option value="2">2 Year</option>
                            <option value="3">3 Year</option>
                            <option value="4">4 Year</option>
                            <option value="5">5 Year</option>
                            <option value="6">6 Year</option>
                            <option value="7">7 Year</option>
                            <option value="8">8 Year</option>
                            <option value="9">9 Year</option>
                            <option value="10">LifeTime</option>
                          </select>
                        </div>
                      </div>
                      <div className="detailcheckout mt-4">
                        <div className="listcheckout pe-2">
                          <h6 className="mb-1">Coupon Code</h6>
                          <input
                            type="text"
                            name="couponCode"
                            value={input.couponCode}
                            disabled={input.appliedCoupon}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                couponCode: e.target.value,
                              })
                            }
                            style={{ color: "#222" }}
                            id="couponCode"
                            placeholder="Enter coupon code"
                            className="m-0 form-control rounded"
                          />
                        </div>
                        {
                          input.appliedCoupon ?
                            <button onClick={() => unapplyCoupon()} className="btn-main btn-sm">
                              <IoIosRemoveCircleOutline />
                            </button>
                            :
                            <button onClick={() => applyCoupon()} className="btn-main btn-sm">
                              Apply
                            </button>
                        }
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-4 d-flex align-items-center">
                          <MdOutlineAccountBalanceWallet
                            size={50}
                            color="#06aeb1"
                          />
                          <div className="heading mt-3 ms-2">
                            <p>Your Balance</p>
                            <div className="subtotal">
                              {priceUpToDigit(userBalance)}{" "}
                              {APP_DATA.CURR_CURRENCY}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex align-items-center">
                          <BsCart4 size={50} color="#06aeb1" />
                          <div
                            className="heading mt-3 ms-1"
                            style={{ maxWidth: "60%" }}
                          >
                            <p>Your Purchase</p>
                            <div className="subtotal">
                              <ReactFitty>{nft.domain}</ReactFitty>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex align-items-center">
                          <IoPricetagsOutline size={50} color="#06aeb1" />
                          <div className="heading mt-3 ms-2">
                            <p>Item Price</p>
                            <div className="subtotal">
                              {priceUpToDigit(nft?.total_bnb_price)}{" "}
                              {APP_DATA.CURR_CURRENCY}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center">
                        <div className="heading mt-2 mb-0">
                          <h4 className="checkout-total">
                            <span>Total Price </span>{" "}
                            {
                              input.appliedCoupon ?
                                <s>
                                  {priceUpToDigit(
                                    totalPrice ? totalPrice : nft?.total_bnb_price
                                  )}{" "}
                                  {APP_DATA.CURR_CURRENCY}
                                </s>
                                :
                                <>
                                  {priceUpToDigit(
                                    totalPrice ? totalPrice : nft?.total_bnb_price
                                  )}{" "}
                                  {APP_DATA.CURR_CURRENCY}
                                </>
                            }

                          </h4>
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          className="btn-main btn-secondary mt-3 lead"
                          onClick={onClickCheckout}
                        >
                          Checkout
                        </button>
                        <button
                          className="btn-main btn-black mt-3 ms-3 lead"
                          onClick={onClickCheckout}
                        >
                          Add To Cart
                        </button>
                        {/* <button
                          className="btn-main mt-3 ms-3 lead"
                          onClick={onClickClaimNow}
                        >
                          Claim Now
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {/* <hr className="my-4 border-bottom-pink mx-3" /> */}
          <div className="row mt-5 pt-4 mx-0">
            <div className="col-12">
              {/* <hr className="my-4 mt-5 border-bottom-pink" /> */}
              <div className="domain-blocks section-2">
                <h3>Suggested Domains</h3>
                {suggestedDomains &&
                  suggestedDomains?.length > 0 &&
                  suggestedDomains
                    .filter((item) => item.status === "available")
                    .map((domain1, domIndex1) => (
                      <div className="domain-card suggested-domain-row d-flex justify-content-between align-items-center mb-3 p-3 border rounded">
                        <div className="d-flex gap-4 card-heading">
                          <RiHeartAddLine className="outline-heart" size={25} />
                          <h4 className="m-0">
                            {domain1.domain ? domain1.domain : "Flower.com"}
                          </h4>
                          <div className="dummy"></div>
                        </div>
                        <div className="d-flex gap-2 align-items-center justify-content-end card-body">
                          <div className="d-flex flex-column">
                            <h5 className="m-0">
                              {priceUpToDigit(domain1.total_bnb_price)}{" "}
                              {APP_DATA.CURR_CURRENCY}
                            </h5>
                            <p className="m-0 text-end">
                              $ {priceUpToDigit(domain1.registration_price)}
                            </p>
                          </div>
                          <button
                            onClick={() => buyNow(domain1)}
                            className="btn-main lead py-0"
                          >
                            <IoCartOutline
                              size={18}
                              style={{ marginTop: "-2px" }}
                            />{" "}
                            Buy Now
                          </button>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="pb-0"
        style={{ background: "white", paddingTop: "250px" }}
      >
        <Footer1 type="pink" overlay={true} />
      </section>
    </div>
  );
}
