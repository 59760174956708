import { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { Axios } from "../../core/axios";
import Cookies from "js-cookie";
import { priceUpToDigit } from "../../utils";
import { APP_DATA } from "../../core/utils";
import { AiTwotoneDelete } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";
import { useWeb3React } from "@web3-react/core";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .navbar.sticky.white{
    background: #fff !important;
    border-bottom: none;
    .navbar-logo-white{
      display: none;
    }
    .navbar-logo-pink{
      display: block;
    }
  }
  .btn-main.inline.white{
    background: rgba(255, 234, 242, 1);
    color: black !important;
  }

  .top-hero-header{
    position: relative;
    .top-hero-header-inner{
      width: 80%;
      position: absolute;
      bottom: 50px;
      left: 50%;
      margin-left: -40%;
      display: flex;
      background: #fff;
      border-radius: 120px;
      padding: 10px;
      box-shadow: 0px 54px 80px -28px #ddd;
      border: 10px solid rgba(0,0,0,0.03);

      .MuiStepLabel-label{
        margin-top: 8px !important;
        font-family: Exo;
      }

      .right-stepper-section{
        background: rgba(255, 207, 226, 1);
        border-radius: 100px;
        height: 80px;
        width: 80px;
        padding: 15px;
      }

      .top-hero-search-box{
        position: relative;
        flex: 1;

        .top-hero-search-input{
          width: 100%;
          height: 50px;
          border: none;
          outline: none;
          background: rgba(0, 0, 0, 0.06);
          padding: 0px 20px;
          border-radius: 18px;
        }
        .top-hero-search-btn{
          position: absolute;
          right: 5px;
          top: 5px;
          border: none;
          outline: none;
          background: transparent;
          img{
            height: 35px;
          }
        }
      }
    }
  }
.
  .domain-view {
    border: 1px solid #06aeb1;
    border-radius: 6px;
    overflow: hidden;
    height: 100% !important;

    h1 {
      margin: 0px;
    }
  }

  .fill-heart {
    font-size: 30px;
    color: red;
  }

  .outline-heart {
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
  .domain-card{
    border-bottom: 1px solid #06aeb1;
  }
`;

export default function Cart() {
  const [data, setData] = useState({});
  const [input, setInput] = useState([]);
  const customHeaders = {
    Authorization: "Token " + Cookies.get("Token")
  }
  const [total, setTotal] = useState(0);
  const [coinPrice, setCoinPrice] = useState(296.95);
  const { library, active, account } = useWeb3React();

  const getCart = () => {
    Axios.get(`/domain-cart?is_web3=${(Cookies.get("W2W3Switch") && Cookies.get("W2W3Switch") == 'true') ? 'True' : 'False'}`, { headers: customHeaders }).then(resp => {
      setInput(resp.data?.data?.cart?.map(() => ({ numberOfUnit: '10000', numberOfYears: '1', couponCode: '', couponApplied: false })))
      setData(resp.data.data);
    }).catch(() => { })
  }

  const getWeb3Price = () => {
    let query =
      "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=USD";
    if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
      query =
        "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=USD";
    }
    let apiConPrice;
    axios.get(query).then((binancecoin) => {
      if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
        apiConPrice = parseFloat(binancecoin.data["matic-network"].usd);
      } else {
        apiConPrice = parseFloat(binancecoin.data.binancecoin.usd);
      }
      setCoinPrice(apiConPrice);
    }).catch((err) => { });
  }

  useEffect(() => {
    // getWeb3Price();
    getCart();
  }, []);

  useEffect(() => {
    if (input?.length > 0)
      getTotal();
  }, [data, input])

  const onChangeHand = (e, i) => {
    if (input.length > i) {
      let tempInput = input;
      tempInput[i][e.target.name] = e.target.value;
      setInput(JSON.parse(JSON.stringify(tempInput)));
    }
  }

  const getTotal = () => {
    let mTotal = 0;
    data?.cart?.forEach((ele, index) => {
      if (input.length > index && input[index].couponApplied == true) return;
      if (input.length > index) {
        mTotal += (ele.price * (Number(input[index].numberOfYears) ? Number(input[index].numberOfYears) : 1));
      } else {
        mTotal += (ele.price);
      }
    })
    setTotal(mTotal);
  }

  const removeFromCart = (mDomain) => {
    Axios.post("/domain-cart", {
      domain_name: mDomain.domain_name,
      price: mDomain.price,
      is_web3: Cookies.get("W2W3Switch") ? true : false,
      operation: 'remove'
    }, { headers: customHeaders }).then(resp => {
      getCart();
    }).catch(err => {
      console.log(err);
    })
  }

  // const placeOrder = () => {
  //   Axios.post("/place-order", { is_claim: false, is_web3: Cookies.get("W2W3Switch") ? Cookies.get("W2W3Switch") : false }, { headers: customHeaders }).then(resp => {
  //     console.log(resp.data)
  //   }).catch(err => {
  //     console.log(err, "2");
  //   })
  // }

  const checkoutBulk = () => {
    let contract = new library.eth.Contract(
      APP_DATA.ABI,
      APP_DATA.contractAddress
    );
    if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
      contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressETH
      );
    } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
      contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressMATIC
      );
    }
    let mAccounts = [];
    let mintUnits = [];
    let urls = [];
    let prices = [];
    let years = [];

    const mData = data?.cart?.map((ele, index) => {
      mAccounts.push(account);
      mintUnits.push(parseInt(input[index].numberOfUnit));
      urls.push(ele.domain_name);
      if (input[index].couponApplied) {
        prices.push(library.utils.toWei("0"));
      } else {
        prices.push(library.utils.toWei((ele?.price / coinPrice)?.toString()));
      }
      years.push(input[index].numberOfYears);

      return ({
        id: ele.id,
        mint_unit: input[index].numberOfUnit,
        year: input[index].numberOfYears,
        coupon_code: input[index].couponCode && input[index].couponApplied ? input[index].couponCode : null,
        is_web3: Cookies.get("W2W3Switch") && Cookies.get("W2W3Switch") == 'true' ? true : false,
        domain_name: ele.domain_name,
        price: ele.price,
      })
    })

    const mPrice = library.utils.toWei((parseFloat(total) / coinPrice)?.toString())

    contract.methods.mintMultiple(
      mAccounts,
      mintUnits,
      urls,
      prices,
      years,
      Cookies.get("W2W3Switch") && Cookies.get("W2W3Switch") == 'true' ? 2 : 1,
    )
      .send({ from: account, value: mPrice })
      .then((transaction) => {
        Axios.post("/place-order", mData, { headers: customHeaders }).then(resp => {
          Swal.fire({
            title: "Order Placed",
            text: "Your Order Placed Successfully.",
            icon: 'success',
          }).then(() => {
            window.location.href = "/profile/" + account;
          });
        }).catch(err => {
          console.log(err, "1");
        })
      }).catch(err => {
        console.log(err);
      });

  }

  // const claimBulk = () => {
  //   Axios.post("/place-order", { is_claim: true, is_web3: Cookies.get("W2W3Switch") ? Cookies.get("W2W3Switch") : false }, { headers: customHeaders }).then(resp => {
  //     console.log(resp.data)
  //   }).catch(err => {
  //     console.log(err, "2");
  //   })
  // }

  const applyCoupon = (data, domain, index) => {
    Axios.get(`/validate-reward-coupon?domain_name=${domain}&coupon_code=${data}`, { headers: customHeaders }).then(resp => {
      let tempData = JSON.parse(JSON.stringify(input));
      if (resp.data.data?.is_redeemed) {
        toast.warn("Coupon has already redeemed.");
        tempData[index].couponApplied = false;
      } else {
        toast.success("Coupon has been applied.");
        tempData[index].couponApplied = true;
      }
      setInput(tempData);
    }).catch(err => {
      let tempData = JSON.parse(JSON.stringify(input));
      tempData[index].couponApplied = false;
      setInput(tempData);
      toast.error("Invalid coupon code !!!")
    })
  }

  const unapplyCoupon = (index) => {
    let tempData = JSON.parse(JSON.stringify(input));
    tempData[index].couponApplied = false;
    setInput(tempData);
  }

  return (
    <>
      <GlobalStyles />

      <section>
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-6 domain-view-left ps-0 pt-4">
              <img src="/img/not-found.jpg" className="w-100" />
            </div>
            <div className="col-md-6">
              <div style={{ border: "2px solid #06aeb1" }}>
                {
                  data?.cart?.length <= 0 ?
                    <div className="p-3 text-center">
                      <h2>Your Cart is empty !!!</h2>
                    </div>
                    :
                    <div className="row m-0 p-0">
                      {
                        data?.cart?.map((mDomain, index) => (
                          <div className="domain-card suggested-domain-row p-2 px-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="card-heading">
                                <h3 className="m-0 fs-30">
                                  {mDomain.domain_name ? mDomain.domain_name : "Flower.com"}
                                </h3>
                              </div>
                              <div className="d-flex gap-2 align-items-center justify-content-end card-body pe-0">
                                <div className="d-flex flex-column">
                                  <h5 className="m-0">
                                    {((input.length > index) ? input[index].couponApplied : false) ?
                                      <s>
                                        $ {priceUpToDigit((
                                          (input.length > index) && Number(input[index].numberOfYears)
                                            ? Number(input[index].numberOfYears) : 1
                                        ) * mDomain.price)}
                                      </s>
                                      :
                                      <>
                                        $ {priceUpToDigit((
                                          (input.length > index) && Number(input[index].numberOfYears)
                                            ? Number(input[index].numberOfYears) : 1
                                        ) * mDomain.price)}
                                      </>
                                    }

                                  </h5>
                                </div>
                                <button
                                  onClick={() => removeFromCart(mDomain)}
                                  className="btn-main btn-sm lead px-3 py-1"
                                >
                                  <AiTwotoneDelete
                                    size={18}
                                    style={{ marginTop: "-4px" }}
                                  />
                                  {/* Remove */}
                                </button>
                              </div>
                            </div>
                            {
                              (Cookies.get("W2W3Switch") && Cookies.get("W2W3Switch") == 'true') ?
                                <></>
                                :
                                <div className="row">
                                  <div className="col-md-6">
                                    <h6 className="mb-1">Number of unit to be mint</h6>
                                    <input
                                      className="form-control"
                                      placeholder="Number of Unit to be mint"
                                      name="numberOfUnit"
                                      value={(input.length > index) ? input[index].numberOfUnit : ''}
                                      onChange={(e) => onChangeHand(e, index)}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <h6 className="mb-1">Number of years</h6>
                                    <select
                                      value={(input.length > index) ? input[index].numberOfYears : ''}
                                      name="numberOfYears"
                                      onChange={(e) => onChangeHand(e, index)}
                                      className="m-0 form-control rounded"
                                      disabled
                                      readOnly
                                    >
                                      <option value="">Select Year</option>
                                      <option value="1">1 Year</option>
                                      <option value="2">2 Year</option>
                                      <option value="3">3 Year</option>
                                      <option value="4">4 Year</option>
                                      <option value="5">5 Year</option>
                                      <option value="6">6 Year</option>
                                      <option value="7">7 Year</option>
                                      <option value="8">8 Year</option>
                                      <option value="9">9 Year</option>
                                      <option value="10">LifeTime</option>
                                    </select>
                                  </div>
                                </div>
                            }
                            <div className="row">
                              <div className="col-md-12">
                                <h6 className="mb-1">Enter Coupon Code</h6>
                                <div className="d-flex gap-2">
                                  <input
                                    className="form-control"
                                    placeholder="Coupon Code"
                                    name="couponCode"
                                    disabled={((input.length > index) ? input[index].couponApplied : false)}
                                    value={(input.length > index) ? input[index].couponCode : ''}
                                    onChange={(e) => onChangeHand(e, index)}
                                  />
                                  {
                                    ((input.length > index) ? input[index].couponApplied : false) ?
                                      <button onClick={() => unapplyCoupon(index)} className="btn-main btn-sm">
                                        <IoIosRemoveCircleOutline />
                                      </button>
                                      :
                                      <button onClick={() => applyCoupon((input.length > index) ? input[index].couponCode : '', mDomain.domain_name, index)} className="btn-main btn-sm">
                                        Apply
                                      </button>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      <div className="domain-card suggested-domain-row d-flex justify-content-between align-items-center p-3">
                        <div className="d-flex p-0 align-items-center justify-content-between card-body">
                          <div className="d-flex">
                            <h5 className="m-0">
                              <span>Total Item : </span>
                              <span>{data.count}</span>
                            </h5>
                          </div>
                          <div className="d-flex flex-column text-end">
                            <h5 className="m-0">
                              <span>Sub Total : </span>

                              <span className="fs-30">{priceUpToDigit(parseFloat(total) / coinPrice)} {APP_DATA.CURR_CURRENCY}</span>
                            </h5>
                            <p className="mb-0">$ {total}</p>
                          </div>
                        </div>
                      </div>
                      <div className="m-0 p-0 d-flex">
                        <button onClick={checkoutBulk} className="btn-main w-100 rounded-0">
                          Checkout
                        </button>
                        {/* <button onClick={claimBulk} ref={(el) => el && el.style.setProperty('color', '#000', "important")} className="btn-main btn-secondary w-100 rounded-0">
                          Claim Now
                        </button> */}
                      </div>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}