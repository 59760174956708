import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Filler,
	Tooltip,
	Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FiTwitter, FiYoutube } from 'react-icons/fi';
import { RiGoogleLine } from 'react-icons/ri';
import { HiHashtag } from 'react-icons/hi';
import { ReactFitty } from "react-fitty";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);
const options = {
	type: "line",
	data: {
		labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

		datasets: [{
			label: "",
			borderColor: '#bbb',
			fill: "start",
			tension: 0.4,
			circular: false,
			// backgroundColor: '#fff',
			// backgroundColor: (context) => {
			// 	const ctx = context.chart.ctx;
			// 	const gradient = ctx.createLinearGradient(0, 0, 0, 100);
			// 	gradient.addColorStop(0, "rgba(10, 10, 10, 0.3)");
			// 	gradient.addColorStop(1, "rgba(10, 10, 10, 1)");
			// 	return gradient;
			// },
			pointBackgroundColor: 'transparent',
			pointBorderColor: 'transparent',
			borderWidth: 3,
			padding: 0,

			data: [0, 15, 10, 30, 45, 50, 40, 35, 55, 60, 75, 65]
		}]
	},
	options: {
		title: false,
		responsive: true,
		animations: {
			tension: {
				duration: 1000,
				easing: 'linear',
				from: 0.5,
				to: 0.5,
			}
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				display: false
			},
			x: {
				display: false
			}
		},
		elements: {
			point: {
        radius: 0, // Hide points on the line
      },
		},
		layout: {
			padding: 0,
			autoPadding: false,
		}
	}
};

export default function ApexChart({ title, type, value }) {

	return (
		<div className="card border-0">
			<div className="card-header d-flex bg-white align-items-start border-0 pb-0">
				<div className="me-auto">
					<p className="mb-2 fs-13" style={{ fontWeight: "500" }}>{title}</p>
					<h2 className="text-black mb-0 font-w600" style={{fontSize: "20px"}}>
						{value ? value : 0}
					</h2>
				</div>
				{
					type === "1" ? <HiHashtag fontSize={50} /> : type === "2" ? <RiGoogleLine fontSize={50} /> : <FiYoutube fontSize={50} />
				}
			</div>
			<div className="d-flex p-0">
				<Line options={options.options} data={options.data} />;
			</div>
		</div>
	);
}
