import { useEffect, useState } from "react";
import { ReactFitty } from "react-fitty";
import { createGlobalStyle } from "styled-components";
import { APP_DATA } from "../../core/utils";
import { priceToBNB, priceUpToDigit, unixToDateTime } from "../../utils";
import SocialShare from "../components/SocialShare";
import ApexChart from "../components/Charts/ApexChart";
import axios from "axios";
import { RiAuctionLine, RiHeartAddLine, RiShoppingCartLine } from "react-icons/ri";
import { MdOutlineAccountBalanceWallet, MdOutlineDns } from "react-icons/md";
import { BsCart4 } from "react-icons/bs";
import { IoCartOutline, IoPricetagsOutline } from "react-icons/io5";
import Footer1 from "../components/footer1";
import { useWeb3React } from "@web3-react/core";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import Web3 from "web3";
import { Link } from "@reach/router";
import { GrShare } from "react-icons/gr";
import { AiOutlineDownload, AiOutlineTag } from "react-icons/ai";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .btn-main svg{width:18px;height:18px;margin-right:3.5px;}
  .navbar.sticky.white{
    background: #fff !important;
    border-bottom: none;
    .navbar-logo-white{
      display: none;
    }
    .navbar-logo-pink{
      display: block;
    }
  }
  .col-white {
      color: #0d0c22;
  }
  .text-dull{
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .btn-main.white{
    background: rgba(131,100,266, .2);
  }
  
  .domain-main{
    //min-height: 400px !important;
  }
  .domain-main-wrapper{
    possition:relative;
    height:300px;
    
    -webkit-box-shadow: 0 2.4px 10px rgb(0 0 0 / 10%);
            box-shadow: 0 2.4px 10px rgb(0 0 0 / 10%);
    .item-footer{
      border-top: 1px solid #ccc;
    }
  }
  .my-domain-main-wrapper{
    &::before{z-index: 1 !important;}
    &::after{z-index: 1 !important;}
  }
  .border-radius-20{
    border-radius: 20px;
  }
  .estimated-price{
    font-size: 15px;
    border: 1px solid #f20c6a;
    border-radius: 25px;
    padding: 2px 10px;
  }
`;

function TradeDetails({ nftId }) {
  const [nft, setNft] = useState({});
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState({});

  const [buyNowQty, setBuyNowQty] = useState(0);
  const [price, setPrice] = useState({
    itemPrice: 0,
    servicePrice: 0,
    totalPrice: 0,
  });
  const { library, active, account } = useWeb3React();

  const [openMenu, setOpenMenu] = useState(1);
  const [userBalance, setUserBalance] = useState("");

  const getNftItemData = async () => {
    let web3 = new Web3(APP_DATA.RPC_BNB);
    let contract = new web3.eth.Contract(
      APP_DATA.ABI,
      APP_DATA.contractAddress
    );
    if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
      web3 = new Web3(APP_DATA.RPC_ETH);
      contract = new web3.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressETH
      );
    } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
      web3 = new Web3(APP_DATA.RPC_MATIC);
      contract = new web3.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddressMATIC
      );
    }
    let itemData = await contract.methods.idToMarketItem(nftId).call();
    let tokenId = itemData.tokenId;
    let mint_time = await contract.methods.Mint_time(tokenId).call();
    let initial_price = await contract.methods.intial_price(tokenId).call();
    initial_price = web3.utils.fromWei(initial_price);
    let max_supply = await contract.methods.max_supply(tokenId).call();
    let minter_address = await contract.methods.Minter_address(tokenId).call();
    let balance_of;
    if (account)
      balance_of = await contract.methods.balanceOf(account, nftId).call();
    let token_uri = await contract.methods.tokenURI(tokenId).call();
    let history = [];
    for (let i = 0; i <= 10; i++) {
      try {
        let tempHistory = [];
        let t = await contract.methods.history_a(tokenId, i).call();
        tempHistory.push(t);
        t = await contract.methods.history_amt(tokenId, i).call();
        tempHistory.push(t);
        t = await contract.methods.history_p(tokenId, i).call();
        tempHistory.push(web3.utils.fromWei(t));
        t = await contract.methods.history_t(tokenId, i).call();
        tempHistory.push(t);
        history.push(tempHistory);
      } catch (error) {
        break;
      }
    }
    let title, image, myIcon;
    let token_uri_split = token_uri?.split(".");
    if (token_uri_split.includes("infura-ipfs")) {
      const resAPI = await axios.get(token_uri);
      title = resAPI.data.name;
    }
    let price = web3.utils.fromWei(itemData.PricePerUnit);
    // getStats(title);
    setNft({
      ...nft,
      ...itemData,
      title,
      balance_of,
      history,
      initial_price,
      minter_address,
      tokenId,
      max_supply,
      image,
      myIcon,
      mint_time,
      price,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (nftId && account) {
      getNftItemData();
    }
  }, [account]);

  const getBalance = async () => {
    if (library) {
      try {
        const res = await library.eth.getBalance(account);
        setUserBalance(library.utils.fromWei(res));
      } catch (error) { }
    }
  };
  const regEx = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
  const positveIntegers = /^[1-9]\d*$/;

  useEffect(() => {
    if (account) {
      getBalance();
    }
  }, [account])

  const calculateTotalNftFee = async (qty) => {
    if (qty !== "") {
      let web3 = new Web3(APP_DATA.RPC_BNB);
      let contract = new web3.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddress
      );
      if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
        web3 = new Web3(APP_DATA.RPC_ETH);
        contract = new web3.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddressETH
        );
      } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
        web3 = new Web3(APP_DATA.RPC_MATIC);
        contract = new web3.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddressMATIC
        );
      }
      let total_fee_for_sale = await contract.methods
        .total_fee_for_sale(parseInt(nftId), parseInt(qty))
        .call();
      total_fee_for_sale = web3.utils.fromWei(total_fee_for_sale);
      setPrice({ totalPrice: total_fee_for_sale });
    }
  };


  const onClickNftCheckout = async () => {
    if (!active) {
      toast.error("Please Connect Wallet To Continue!!!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (library) {
      setLoading(true);
      let contract = new library.eth.Contract(
        APP_DATA.ABI,
        APP_DATA.contractAddress
      );
      if (localStorage.getItem("chainId") === APP_DATA.chainId2) {
        contract = new library.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddressETH
        );
      } else if (localStorage.getItem("chainId") === APP_DATA.chainId3) {
        contract = new library.eth.Contract(
          APP_DATA.ABI,
          APP_DATA.contractAddressMATIC
        );
      }
      let total_fee_for_sale = await contract.methods
        .total_fee_for_sale(parseInt(nftId), parseInt(buyNowQty))
        .call();

      contract.methods
        .saleItem(parseInt(nftId), parseInt(buyNowQty))
        .send({ from: account, value: total_fee_for_sale })
        .then((transaction) => {
          toast.success("Buy Nft Successfully.", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          // setopenNftCheckout(false);
          setTimeout(() => window.open("/auction", "_self"), 2000);
        });
    }
  };

  return (
    <div>
      <GlobalStyles />
      <section className="mt-3 pt-0 mt-xl-5 pt-xl-5" style={{ background: "#fff" }}>
        <div className="container">
          <div className="row mt-md-3 pt-md-4">
            {loading ? (
              <div className={`siteLoader ${loading ? "page active" : ""}`}></div>
            ) : (
              <>
                <div className="col-md-6 order-md-1 order-2 border-left ps-3">
                  <div className="item_info position-relative">
                    <div className="row">
                      <div className="col-md-8">
                        <h3 className="mb-1 fw-600 text-dull">Domain Information</h3>

                        <div className="row">
                          <div className="col-md-12">
                            <h2 className="mb-2 w-100 fw-800">
                              <ReactFitty minSize="1" maxSize="30">
                                {nft.title}
                              </ReactFitty>
                            </h2>
                            {/* </div> */}
                            {/* <div className="col-md-6 d-flex align-items-center"> */}
                            <h4 className="mb-1 fw-500 text-dull">
                              {priceUpToDigit(nft.price)} {APP_DATA.CURR_CURRENCY}{" "}
                              {/* <span className="mb-3 fw-800 text-dull">
                                | ${nft.price}{" "}
                              </span> */}
                              (per unit price)
                            </h4>
                          </div>
                        </div>

                        {nft?.estimatedPrice && <h4 className="mb-4 fw-500 text-dull">
                          <span className="estimated-price">Estimated Price :</span> <b>${nft.estimatedPrice}</b>
                        </h4>}

                      </div>

                      {/* <div className="col-md-4 text-md-end" style={{ paddingRight: "50px" }}>
                        {account ? (
                          !nftId ? (
                            <></>
                          ) : !nft.seller ? (
                            <>
                              <button
                                className="btn-capsule"
                                onClick={() => {
                                  account
                                    ? downloadInvoice()
                                    : toast.error(
                                      "Please Connect Wallet To Continue!!!"
                                    );
                                }}
                              >
                                <AiOutlineDownload /> Download Invoice
                              </button>
                              <button
                                className="btn-capsule me-2 me-md-0"
                                onClick={() => {
                                  account
                                    ? (window.location.href =
                                      "/nft-detail/" + nftId)
                                    : toast.error(
                                      "Please Connect Wallet To Continue!!!"
                                    );
                                }}
                              >
                                <MdOutlineDns /> Manage DNS
                              </button>
                            </>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div> */}

                      {/* <SocialShare data={nft} /> */}

                      {nft?.tags && (
                        <div className="nft-tags">
                          {nft.tags.split(",").map((ele, i) => (
                            <div key={i} className="nft-tag">
                              {ele}
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="row mt-4 pe-0">
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="1"
                              title="HashTag Mentions"
                              subtitle=""
                              value={stats.hashtags ? stats.hashtags : 0}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="2"
                              title="Google Search Trends"
                              subtitle=""
                              value={stats.google}
                            />
                          </div>
                        </div>
                        <div className="col-md-4 pe-0">
                          <div className="chart-card-block">
                            <ApexChart
                              type="3"
                              title="YouTube Search"
                              subtitle=""
                              value={stats.twitter}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="de_tab mt-4">
                        {localStorage.getItem("item_data_buy") && !nftId ? (
                          <></>
                        ) : (
                          <ul className="de_nav">
                            <li
                              id="Mainbtn0"
                              className={openMenu === 1 ? "active" : ""}
                            >
                              <span onClick={() => setOpenMenu(1)}>Details</span>
                            </li>
                            {nft.mint_time && (
                              <li
                                id="Mainbtn1"
                                className={openMenu === 2 ? "active" : ""}
                              >
                                <span onClick={() => setOpenMenu(2)}>
                                  History
                                </span>
                              </li>
                            )}
                          </ul>
                        )}

                        <div className="de_tab_content">
                          {openMenu === 1 && (
                            <div className="tab-1 onStep fadeIn">
                              <div className="d-block mb-3">
                                {nft?.tokenId && (
                                  <table className="table detail-table">
                                    <thead>
                                      <tr>
                                        <th>ID</th>
                                        <th>Listed Time</th>
                                        <th>Max Supply</th>
                                        {nft?.units_left && <th>Unit left</th>}
                                        {nft?.totalprice && (
                                          <th>Current Price</th>
                                        )}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <span className="detail-id">
                                            {nft?.tokenId}
                                          </span>
                                        </td>
                                        <td>
                                          <h6>
                                            {unixToDateTime(nft?.mint_time).slice(
                                              0,
                                              11
                                            )}
                                            <span>
                                              {unixToDateTime(
                                                nft?.mint_time
                                              ).slice(
                                                12,
                                                unixToDateTime(nft?.mint_time)
                                                  ?.length
                                              )}
                                            </span>
                                          </h6>
                                        </td>
                                        <td>{nft?.max_supply}</td>
                                        {nft?.units_left && (
                                          <td>
                                            <span className="unit">
                                              {nft?.units_left}
                                            </span>
                                          </td>
                                        )}
                                        {nft?.totalprice && (
                                          <td>
                                            <h6>
                                              {priceToBNB(nft?.totalprice)}{" "}
                                              {APP_DATA.CURR_CURRENCY}
                                            </h6>
                                          </td>
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                )}
                              </div>
                            </div>
                          )}

                          {openMenu === 2 && (
                            <div className="tab-2 onStep fadeIn">
                              <table className="table">
                                <tbody>
                                  <tr>
                                    <th>Address</th>
                                    <th>Amount</th>
                                    <th>Price</th>
                                    <th>Time</th>
                                  </tr>
                                  <tr>
                                    <td>
                                      {nft?.minter_address.slice(0, 5) +
                                        "..." +
                                        nft.minter_address.slice(
                                          nft.minter_address.length - 4,
                                          nft.minter_address.length
                                        )}
                                      <Link to="/">
                                        <GrShare className="ms-2" />
                                      </Link>
                                    </td>
                                    <td>{nft?.max_supply}</td>
                                    <td>
                                      {priceUpToDigit(nft.price)}{" "}
                                      {APP_DATA.CURR_CURRENCY}
                                    </td>
                                    <td>{unixToDateTime(nft.mint_time)}</td>
                                  </tr>
                                  {nft?.history?.map((ele) => {
                                    return (
                                      <tr>
                                        <td>
                                          {ele[0].slice(0, 5) +
                                            "..." +
                                            ele[0].slice(
                                              ele[0].length - 5,
                                              ele[0].length
                                            )}
                                        </td>
                                        <td>{ele[1]}</td>
                                        <td>
                                          {priceUpToDigit(ele[2])}{" "}
                                          {APP_DATA.CURR_CURRENCY}
                                        </td>
                                        <td>{unixToDateTime(ele[3])}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {/* <hr className="hr-line" /> */}
                          {/* button for checkout */}

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-md-6 order-md-2 order-1">
                  <div className="checkout">
                    <div className="maincheckout">
                      <div className="heading text-center">
                        <h3>Nft Checkout</h3>
                      </div>

                      <div className="detailcheckout mt-4">
                        <div className="listcheckout">
                          <h6>
                            Enter quantity.
                            <span className="color"> {nft.units_left} available</span>
                          </h6>
                          <input
                            type="number"
                            name="buyNowQty"
                            value={buyNowQty}
                            onChange={(e) => {
                              const flag = regEx.test(e.target.value);
                              setBuyNowQty(() => (flag ? e.target.value : ""));
                              calculateTotalNftFee(e.target.value);
                            }}
                            style={{ color: "#222" }}
                            id="buyNowQty"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="checkout-panel mt-0 py-4">
                        <div className="row gx-3">
                          <div className="col-md-6">
                            <div className="nft-check-block">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <div className="icon">
                                    <img src="/img/checkout-icon-01.svg" alt="Like" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-0">
                                  <div className="detail">
                                    <h6>Your Balance</h6>
                                    <h5>
                                      {priceUpToDigit(userBalance)}{" "}
                                      {APP_DATA.CURR_CURRENCY}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="nft-check-block price-per-unit">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <div className="icon">
                                    <img src="/img/checkout-icon-03.svg" alt="Like" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-0">
                                  <div className="detail">
                                    <h6>Per unit price</h6>
                                    <h5>
                                      {priceUpToDigit(nft.price)} {APP_DATA.CURR_CURRENCY}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-6 center-block">
                            <div className="nft-check-block">
                              <div className="d-flex">
                                <div className="flex-shrink-0">
                                  <div className="icon">
                                    <img src="/img/checkout-icon-02.svg" alt="Like" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-0">
                                  <div className="detail">
                                    <h6>You are purchasing</h6>
                                    <h5 className="text-nowrap">
                                      <span className="bold">{nft.title}</span>
                                      {/* <span className="bold">
                                        {" "}
                                        from {nft?.author?.username}
                                      </span> */}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <h4 className="checkout-total pt-0 mt-0">
                        <span>Total Price</span>
                        {priceUpToDigit(price.totalPrice)?priceUpToDigit(price.totalPrice):0} {APP_DATA.CURR_CURRENCY}
                      </h4>

                      <div className="text-center mt-0 mb-0">
                        <button
                          className="btn-main lead"
                        onClick={onClickNftCheckout}
                        >
                          Checkout
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <section
        className="pb-0"
        style={{ background: "white", paddingTop: "250px" }}
      >
        <Footer1 type="pink" overlay={true} />
      </section>

    </div>
  )
}

export default TradeDetails