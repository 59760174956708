import { Axios, Canceler } from "../../../core/axios";
import { APP_DATA } from "../../../core/utils";
import * as actions from "../../actions";

export const searchLogoIcons =
  (searchQuery, filter = {}, first = true) =>
  async (dispatch, getState) => {
    dispatch(actions.getLogos.request(Canceler.cancel));

    try {
      let query = `?search=${searchQuery}`;
      if (filter.color) {
        query += `&color=${filter.color}`;
      }
      if (filter.page) {
        query += `&page=${filter.page}`;
      }
      if (filter.style) {
        query += `&style=${filter.style}`;
      }
      const response2 = await Axios.get(
        `${APP_DATA.API_URL}/searchicons${query}`
      );
      let finalData = [];
      if (first) {
        finalData = response2.data;
      } else {
        let t = getState().logo.icons.data;
        let result1 = t.results;
        let result2 = response2.data.results;
        t.results = result1.concat(result2);
        finalData = t;
      }
      dispatch(actions.getLogos.success(finalData));
    } catch (error) {
      dispatch(actions.getLogos.failure(error));
    }
  };

export const searchDomains =
  (searchQuery, industry) => async (dispatch, getState) => {
    dispatch(actions.getDomains.request(Canceler.cancel));

    try {
      const response = await Axios.get(
        `${APP_DATA.API_URL}/getdomain?search=${searchQuery}`
      );

      let finalData = [];
      response?.data?.search_results.map((ele, index) => {
        let obj = ele;
        obj.total_bnb_price = obj.registration_price
          ? parseFloat(obj.registration_price) / 10000
          : 0;
        finalData.push(obj);
      });
      dispatch(actions.getDomains.success({ main: finalData }));

      const response1 = await Axios.get(
        `${APP_DATA.API_URL}/getsuggesteddomain?search=${searchQuery}&page=1`
      );

      let finalData1 = [];
      response1?.data?.suggeted_domain?.map(async (ele1, index1) => {
        let obj1 = ele1;
        obj1.total_bnb_price = obj1.registration_price
          ? parseFloat(obj1.registration_price) / 10000
          : 0;
        finalData1.push(obj1);
      });

      dispatch(
        actions.getDomains.success({ main: finalData, suggested: finalData1 })
      );
    } catch (error) {
      dispatch(actions.getDomains.failure([]));
    }
  };
